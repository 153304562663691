import { useContext, useEffect, useRef, useState, ReactNode } from "react";
import {
  json,
  Params,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Box,
  ListItem,
  Typography,
  Switch,
  Button,
  useTheme,
  Modal,
  Divider,
  Card,
  CardContent,
  Tooltip,
  CardActionArea,
  Collapse,
  Skeleton,
  IconButton,
  TextField,
  CircularProgress,
  Menu,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { NoteSection } from "../components/NoteSection";
import {
  HelpOutline as HelpOutlineIcon,
  DrawOutlined as DrawOutlinedIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  Check as CheckIcon,
  ContentCopy as ContentCopyIcon,
  Spellcheck as SpellcheckIcon,
  Person,
  AssignmentInd,
  AssignmentIndOutlined,
  Edit,
  Check,
  Money,
  MoneyOutlined,
  Paid,
  InfoOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowLeftOutlined,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { SVGIcon } from "../components/SVGIcon";
import {
  formatDate,
  formatDuration,
  stripTags,
  textToParagraph,
} from "../utils/utils";
import { DebugContext } from "../main";
import { Transcript, TranscriptType } from "../components/Transcript";
import { useUIState } from "../context/uiState";
import { DeleteModal } from "../components/DeleteModal";
import EmailNoteModal from "../components/EmailNoteModal";
import { NoteFeedbackBox } from "../components/NoteFeedbackBox";
import {
  AdminEmails,
  AdminTemplate,
  DemoNoteDataType,
  NoteDataType,
  NoteExtraType,
  NoteSectionType,
  Patient,
  PatientEligibility,
} from "../types/types";
import { RoundedButton } from "../styles/CustomButtons";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import React from "react";
import { ConditionalTooltip } from "../components/ConditionalTooltip";
import { useUser } from "../context/user";
import { DismissableCard } from "./Shared/DismissableCard";
import APIService, { API_BASE_URL } from "../services/APIService";
import { useLocation } from "react-router-dom";
import { useThemeContext } from "../context/theme/context";
import SpellModal from "../components/Modals/SpellModal";
import { clarity } from "../services/ClarityService";
import CloseButton from "../components/Buttons/CloseButton";
import TemplatePicker from "../components/Pickers/TemplatePicker";
import { SimpleModal } from "../components/Modals/SimpleModal";
import { ModuleCustomizationView } from "./ModuleCustomizationView";
import { NoteMetadata } from "../components/NoteMetadata";
import { removeScrollBar } from "../styles/globalStyles";
import AssignNoteToPatient from "../components/AssignNoteToPatient";
import LayoutWrapper from "../components/layout/UILayout";
import { ContentCard } from "./Shared/ContentCard";
import {
  GreenBubble,
  RedBubble,
  YellowBubble,
} from "../components/Icons/ColoredBubbles";
import SlideSidePane from "./Shared/SlideSidePane";
import BillingSubmissionView from "./BillingSubmissionView";
import PatientInformationView from "../components/Patient/PatientInformationView";
import ProviderInformationView from "../components/Provider/ProviderInformationView";
import PatientPicker from "../components/Patient/PatientPicker";
import PatientUnassignment from "../components/PatientUnassignment";
import PayerSearch from "../components/Patient/PayerSearch";
import PatientEligibilityView from "../components/Patient/PatientEligibilityView";
import EditIcon from "@mui/icons-material/Edit";
import BillingSidebar from "../components/BillingSidebar";
import AssignPatientToNoteDialog from "../components/Patient/AssignPatientToNoteDialog";
import TestGatherBillingSidebarWithExtra from "../components/Billing/TestGatherBillingSidebarWithExtra";
import TestGatherBillingSidebar from "../components/Billing/TestGatherBillingSidebar";
import BillingSidebarDataCollection from "../components/Billing/BillingSidebarDataCollection";
import BillingSidebarDataPrediction from "../components/Billing/BillingSidebarDataPrediction";
import { trackEvent } from "../utils/analytics_utils";

interface Section {
  id: string;
}

export const NoteLoader = async ({
  params,
}: {
  params: Params;
}): Promise<NoteDataType | null> => {
  const accessToken = localStorage.getItem("accessToken");
  const note = await fetch(
    `${API_BASE_URL}/notes/${params.noteId}?origin=NoteDetailView`,
    {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  )
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: NoteDataType) => {
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return note;
};

export const DemoNoteLoader = async ({
  params,
}: {
  params: Params;
}): Promise<DemoNoteDataType | null> => {
  const note = await fetch(`${API_BASE_URL}/demoNote/${params.noteId}`, {
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((res) => {
      if (res.status === 401) {
        throw json(
          {
            message: "You are not authorized to access this resource.",
            cta: "Login",
          },
          { status: 401 }
        );
      }

      return res.json();
    })
    .then((data: DemoNoteDataType) => {
      return data;
    })
    .catch(() => {
      throw json(
        {
          message: "There has been an error. Please login again.",
          cta: "Login",
        },
        { status: 500 }
      );
    });

  return note;
};

interface NoteViewProps {
  demoMode?: boolean;
}

interface ModuleCustomizationModalInfo {
  showModal: boolean;
  module_id: string;
  note_id: string;
  initialComparisonText: string;
  section_name: string;
  sectionIndex: number;
}

function NoteViewRevamp({ demoMode = false }: NoteViewProps) {
  const [showOnlyMissedSection, setShowOnlyMissedSection] = useState(false);
  const win: Window = window;
  const theme = useTheme();
  const debug = useContext(DebugContext);
  const { noteId } = useParams();
  const note = useLoaderData() as Awaited<ReturnType<typeof NoteLoader>>;
  const demoNote = useLoaderData() as Awaited<
    ReturnType<typeof DemoNoteLoader>
  >;
  const navigate = useNavigate();
  const [noteData, setNoteData] = useState(
    demoMode ? (demoNote as NoteDataType) : (note as NoteDataType)
  );
  const [added, setAdded] = useState<number[]>([]);
  const [saving, setSaving] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<{ mode: "note" | "transcript" }>({
    mode: "note",
  });
  const {
    state: uiState,
    setState: setUIState,
    showAlertBanner,
  } = useUIState();
  const { loadedInChromeExtension } = uiState;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pdfGenerating, setPDFGenerating] = useState(false);

  const [activeSection, setActiveSection] = useState<Section>();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [emailList, setEmailList] = useState<AdminEmails[]>([]);
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const [sendPDFEmailList, setSendPDFEmailList] = useState<string[]>([]);
  const [loadingText, setLoadingText] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [copiedInSession, setCopiedInSession] = useState(false);
  const [loadingSections, setLoadingSections] = useState<string[]>();
  const [editInstructions, setEditInstructions] = useState<string>("");
  const [patRel, setPatRel] = useState<string>("self");

  const intervalRef = useRef<number | undefined>();

  const [showTooltips, setShowTooltips] = useState<boolean>(demoMode);
  const [showChromeExtensionTooltips, setShowChromeExtensionTooltips] =
    useState<boolean>(true);
  const [showNoteOnboarding, setShowNoteOnboarding] = useState<boolean>(false);
  const [selectedSections, setSelectedSections] = useState<number[]>([]);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(noteData.note_title);

  const [patientData, setPatientData] = useState<Patient | null | undefined>(
    undefined
  );

  const [tutorialHeaderText, setTutorialHeaderText] = useState<string>(
    noteData.completion_status === "completed"
      ? "Note complete!"
      : "See something you want to change?"
  );
  const [tutorialDescription, setTutorialDescription] = useState<string>(
    noteData.completion_status === "completed"
      ? "JotPsych has many more features to help you get your admin work done faster and more accurately. We recommend you check out the template builder if you haven't already. Happy charting!"
      : "Teach JotPsych how to write better notes! Click the 'edit' button in any section to interact with the JotPsych AI."
  );

  const [moduleCustomizationModalInfo, setModuleCustomizationModalInfo] =
    useState<ModuleCustomizationModalInfo>({
      showModal: false,
      module_id: "",
      initialComparisonText: "",
      note_id: noteId || "",
      section_name: "",
      sectionIndex: 0,
    });

  const location = useLocation();
  const origin = location.state?.origin; // Access the 'origin' property from state
  const [misspelledWords, setMisspelledWords] = useState<string[]>([]);

  const [matchedWords, setMatchedWords] = useState<string[]>([]);
  const [matchedMisspelled, setMatchedMisspelled] = useState<string[]>([]);
  const { troubleWords, addTroubleWords } = useUser();

  const { userState, updateOnboardingStep, getAccessToken, fetchUserInfo } =
    useUser();
  const { brandLanguage } = useThemeContext();
  const [openSpellingModal, setOpenSpellingModal] = useState(false);
  const [templateIdToRegenerate, setTemplateIdToRegenerate] =
    useState<string>();

  const [isSwitchTemplateCard, setIsSwitchTemplateCard] = useState(false);

  const [missingPatientEligibilityFields, setMissingPatientEligibilityFields] =
    useState<string[]>([]);
  const [missingPatientBillingFields, setMissingPatientBillingFields] =
    useState<string[]>([]);
  const [
    missingProviderEligibilityFields,
    setMissingProviderEligibilityFields,
  ] = useState<string[]>([]);
  const [missingProviderBillingFields, setMissingProviderBillingFields] =
    useState<string[]>([]);
  const [openAssignPatientToNoteDialog, setOpenAssignPatientToNoteDialog] =
    useState(false);

  const handlePatientEligibilitySave = (newPatRel: string) => {
    setPatRel(newPatRel);
    setIsPatientEligibilitySidePaneOpen(false);
  };

  const [
    isPatientInformationSidePaneOpen,
    setIsPatientInformationSidePaneOpen,
  ] = useState(false);

  const [
    isPatientEligibilitySidePaneOpen,
    setIsPatientEligibilitySidePaneOpen,
  ] = useState(false);

  const handleAssignPatientClick = () => {
    setOpenAssignPatientToNoteDialog(true);
  };

  const handleOpenPatientInformationSidePane = () => {
    setIsPatientInformationSidePaneOpen(true);
  };

  const handleOpenPatientEligibilitySidePane = () => {
    setIsPatientEligibilitySidePaneOpen(true);
  };

  const handleClosePatientEligibilitySidePane = () => {
    setIsPatientEligibilitySidePaneOpen(false);
  };

  const handleClosePatientInformationSidePane = () => {
    setIsPatientInformationSidePaneOpen(false);
  };

  const [
    isProviderInformationSidePaneOpen,
    setIsProviderInformationSidePaneOpen,
  ] = useState(false);

  const handleOpenProviderInformationSidePane = () => {
    setIsProviderInformationSidePaneOpen(true);
  };

  const handleCloseProviderInformationSidePane = () => {
    setIsProviderInformationSidePaneOpen(false);
  };

  const [isBillingSidePaneOpen, setIsBillingSidePaneOpen] = useState(false);

  const handleOpenBillingSidePane = () => {
    trackEvent({
      event: "submit_billing_click",
      event_category: "Billing",
      event_label: "Submit Billing on Note Page",
    });
    setIsBillingSidePaneOpen(true);
  };

  const handleCloseBillingSidePane = () => {
    setIsBillingSidePaneOpen(false);
  };

  const handleBillingSubmitted = () => {
    setBillingSubmitted(true);
    handleCloseBillingSidePane();
  };

  const [billingSubmitted, setBillingSubmitted] = useState(false);

  useEffect(() => {
    if (noteData?.billing_claim_status) {
      if (noteData.billing_claim_status === "completed") {
        setBillingSubmitted(true);
      }
    }
  }, [noteData]);

  const handlePatientInfoClick = () => {
    if (patientData) {
      handleOpenPatientInformationSidePane();
    }
  };

  const handlePatientEligibilityClick = () => {
    if (
      patientData &&
      missingPatientEligibilityFields.length === 0 &&
      missingProviderEligibilityFields.length === 0 &&
      patientData.eligibility === "yes"
    ) {
      handleOpenPatientEligibilitySidePane();
    }
  };

  const handleProviderInfoClick = () => {
    handleOpenProviderInformationSidePane();
  };

  const handleAdd = (sectionIndex: number) => {
    if (added.includes(sectionIndex)) {
      setAdded((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    } else {
      setAdded((current) => [...current, sectionIndex].sort((a, b) => a - b)); // Add new section index and sort the array
    }
  };

  const handleUpdateTitle = async () => {
    if (demoMode) {
      setNoteData((prevData) => ({ ...prevData, note_title: editedTitle }));
      setIsEditingTitle(false);
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/updateNoteTitle",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        new_title: editedTitle,
      },
    });

    if (response.ok) {
      setNoteData((prevData) => ({ ...prevData, note_title: editedTitle }));
      setIsEditingTitle(false);
    } else {
      // Handle error
      console.error("Failed to update note title");
    }
  };

  const handleOpenSpellingModal = () => {
    setShowOnlyMissedSection(matchedMisspelled.length === 0);
    setOpenSpellingModal(true);
  };
  const handleSaveEdits = async (sectionIndex: number, editedText: string) => {
    setTutorialHeaderText("Transferring the note to your EHR");
    setTutorialDescription(
      "Once you're done making edits to your note, let's look at how to copy it to your EHR. On the top toolbar, you can press 'Copy all sections' to grab the entire note. If you just want some sections, you can click the + icon next to each section that you want, and then press 'Copy selected sections'."
    );

    // update data locally
    setNoteData((prevNoteData) => {
      const newNoteData = { ...prevNoteData }; // create a copy
      newNoteData.content.sections = [...newNoteData.content.sections]; // create a new reference for the sections array
      newNoteData.content.sections[sectionIndex].edited_text = editedText;
      return newNoteData;
    });

    if (demoMode) {
      return;
    }

    // update data on server
    const accessToken = await getAccessToken();

    setSaving([...saving, sectionIndex]);

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/editSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_index: sectionIndex,
        edited_text: editedText,
      },
    });

    if (response.ok) {
      const data = response.value;
      setSaving((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    }
  };

  const recheckForMisspellings = (sections: NoteSectionType[]) => {
    const misspelledMatches: string[] = [];
    const correctSpellingMatches: string[] = [];

    if (
      !troubleWords ||
      typeof troubleWords !== "object" ||
      troubleWords === null
    ) {
      console.warn(
        "troubleWords is not properly defined or is not a valid object"
      );
      return;
    }

    // Convert troubleWords to an array if it's an object
    const troubleWordsArray = Array.isArray(troubleWords)
      ? troubleWords
      : Object.entries(troubleWords).map(
          ([correct_spelling, misspellings]) => ({
            correct_spelling,
            misspellings: Array.isArray(misspellings)
              ? misspellings
              : [misspellings],
          })
        );

    sections.forEach((section) => {
      const lowerCaseText = section.text.toLowerCase();

      troubleWordsArray.forEach((troubleWord) => {
        const correctSpelling = troubleWord.correct_spelling;
        troubleWord.misspellings.forEach((misspelling) => {
          if (typeof misspelling !== "string") {
            console.error(
              `Non-string misspelling for ${correctSpelling}:`,
              misspelling
            );
            return;
          }
          const lowerCaseMisspelling = misspelling.toLowerCase();
          if (
            lowerCaseText.includes(lowerCaseMisspelling) &&
            !misspelledMatches.includes(lowerCaseMisspelling)
          ) {
            misspelledMatches.push(lowerCaseMisspelling);
            if (!correctSpellingMatches.includes(correctSpelling)) {
              correctSpellingMatches.push(correctSpelling);
            }
          }
        });
      });
    });

    setMatchedMisspelled(misspelledMatches);
    setMatchedWords(correctSpellingMatches);
  };

  const handleSpellingModalSubmit = (
    updates: { original: string; corrected: string }[]
  ) => {
    const updatedSections = noteData.content.sections.map((section) => {
      let updatedText = section.edited_text
        ? section.edited_text
        : section.text;
      updates.forEach(({ original, corrected }) => {
        const regex = new RegExp(`\\b${original}\\b`, "gi");
        updatedText = updatedText.replace(regex, corrected);
        // console.log(corrected);
      });

      if (updatedText !== section.text) {
        handleSaveEdits(
          noteData.content.sections.indexOf(section),
          updatedText
        );
        return {
          ...section,
          edited_text: updatedText,
        };
      }
      return section;
    });

    // console.log(updates, matchedWords, matchedMisspelled);

    setNoteData((prevData) => ({
      ...prevData,
      content: {
        ...prevData.content,
        sections: updatedSections,
      },
    }));

    // Update matchedWords and matchedMisspelled
    const newMatchedWords = matchedWords.filter(
      (word) =>
        !updates.some(
          (update) => update.original.toLowerCase() === word.toLowerCase()
        )
    );
    const newMatchedMisspelled = matchedMisspelled.filter(
      (word) =>
        !updates.some(
          (update) => update.original.toLowerCase() === word.toLowerCase()
        )
    );

    setMatchedWords(newMatchedWords);
    setMatchedMisspelled(newMatchedMisspelled);
    console.log(updates, newMatchedWords, newMatchedMisspelled);

    // Recheck for misspellings
    recheckForMisspellings(updatedSections);

    setOpenSpellingModal(false);
    setSelectedWord(null);
    // console.log(selectedWord);
  };

  const handleRevertEdits = async (sectionIndex: number) => {
    // Revert edits locally
    setNoteData((prevNoteData) => {
      const newNoteData = { ...prevNoteData };
      newNoteData.content.sections = [...newNoteData.content.sections];
      newNoteData.content.sections[sectionIndex].edited_text = "";

      return newNoteData;
    });

    if (demoMode) {
      return;
    }

    // Revert data on server
    const accessToken = await getAccessToken();

    setSaving([...saving, sectionIndex]);

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/revertSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_index: sectionIndex,
      },
    });

    if (response.ok) {
      const data = response.value;
      setSaving((current) =>
        current.filter((section) => section !== sectionIndex)
      );
    }
  };

  const handleSetCopyStatus = async () => {
    if (demoMode) {
      return;
    }

    if (!copiedInSession && !note?.is_copied) {
      // only update is_copied to true if not already set
      const accessToken = await getAccessToken();

      const response = await APIService.makeAPIPostRequest({
        requestString: "/notes/setCopyStatus",
        accessToken: accessToken,
        body: { note_id: noteId },
      });

      if (response.ok) {
        setCopiedInSession(true);
      }
    }
  };

  const handleSendCopyEvents = async (
    module_ids: string[],
    copied_all: boolean
  ) => {
    if (module_ids.length === 0) {
      // exit the function if no module_ids are present
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/recordCopyEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        module_ids: module_ids,
        copied_all: copied_all,
      },
    });
  };

  const handleSectionClick = (sectionIndex: number) => {
    const sectionElement = document.getElementById(`section-${sectionIndex}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
    setActiveSection({ id: String(sectionIndex) });
    setUIState({
      ...uiState,
      sidebar: { isHidden: true },
    });
  };

  const handleSidebarIconClick = (
    event: React.MouseEvent,
    sectionIndex: number
  ) => {
    // Prevent event propagation
    event.stopPropagation();

    handleAddSection(sectionIndex);
  };

  const handleAddSection = (sectionIndex: number) => {
    setSelectedSections((prevSelectedSections) => {
      if (prevSelectedSections.includes(sectionIndex)) {
        return prevSelectedSections.filter((index) => index !== sectionIndex);
      } else {
        return [...prevSelectedSections, sectionIndex];
      }
    });
  };

  const titleCase = (sentence: string) => {
    let trimmedSentence = sentence.trim();

    const words = trimmedSentence.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    const newSentence = words.join(" ");

    return newSentence;
  };

  const handleCopySections = (copyAll = false) => {
    let copyString = "";
    let moduleIds: string[] = [];

    setTutorialHeaderText("Complete the note");
    setTutorialDescription(
      "Now, the note is on your clipboard and ready to be pasted into your EHR. As a final step, let's make sure the note is associated with a patient (if applicable) and then let's press 'Complete Note' in the bottom right toolbar. This moves the note from 'reviewing' to 'completed'."
    );

    // // Format header information
    const headerString =
      `Note Title: ${noteData.note_title}\n` +
      `Provider: ${noteData.issuing_user_name}\n` +
      `Date: ${formatDate(noteData.time_uploaded, "datelong")}\n` +
      `Time: ${
        noteData.transcription_start_timestamp
          ? `${formatDate(noteData.transcription_start_timestamp, "time")} – ` +
            `${formatDate(noteData.transcription_end_timestamp, "time")}`
          : formatDate(noteData.time_uploaded, "time")
      }\n` +
      `Duration: ${
        noteData.transcription_length_seconds
          ? formatDuration(noteData.transcription_length_seconds)
          : "N/A"
      }\n\n`;

    if (copyAll) {
      copyString += headerString; // Add header information to the copy string only if you copy All
    }
    // Iterate over all sections in the note
    (noteData?.content?.sections || []).forEach((section, sectionIndex) => {
      // Check if the section index is in the `selectedSections` array or if copyAll is true
      if (selectedSections.includes(sectionIndex) || copyAll === true) {
        // Clean section name by removing text between parentheses
        const cleanedName = (section.name as string).replace(
          /\s*\([^)]*\)/g,
          ""
        );
        copyString += `===\n${titleCase(cleanedName)}\n===\n\n`;
        copyString += `${
          section.edited_text ? section.edited_text : section.text
        }\n\n`;

        if (section.module_id) {
          moduleIds.push(section.module_id);
        }
      }
    });

    copyString = stripTags(copyString);

    navigator.clipboard.writeText(copyString);
    showAlertBanner("Sections copied to clipboard!", "success", true);

    if (demoMode) {
      return;
    }

    handleSendCopyEvents(moduleIds, copyAll);
    handleSetCopyStatus();
  };

  const handleGeneratePDF = async () => {
    if (demoMode) {
      return;
    }

    setPDFGenerating(true);
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/generatePDF",
      accessToken: accessToken,
      body: {
        note_id: noteId,
      },
    });

    if (response.ok) {
      const data = response.value;

      // start polling
      const polling = setInterval(async () => {
        const statusResponse = await APIService.makeAPIGetRequest({
          requestString: `/notes/checkPDFStatus/${data.pdf_generation_session_id}`,
          accessToken: accessToken,
        });

        if (statusResponse.ok) {
          const statusData = statusResponse.value;
          if (statusData.status === "completed") {
            clearInterval(polling);
            setPDFGenerating(false);
            // Open the PDF in a new tab
            window.open(statusData.download_url, "_blank");
          }
        }
      }, 3000); // polling every 3 seconds
    }
  };

  const handleRegenerateSection = async (
    moduleId: string,
    setAwaitingRegenerationTaskId: (taskId: string) => void
  ) => {
    if (demoMode) {
      return;
    }

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/regenerateSection",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        module_id: moduleId,
      },
    });

    if (response.ok) {
      setAwaitingRegenerationTaskId(response.value.task_id);
    }
  };

  const handleRegenerateNoteWithNewTemplate = async () => {
    if (!templateIdToRegenerate) {
      return;
    }

    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const response = await APIService.makeAPIPostRequest({
      requestString:
        "/noteProcessingSession/retryNoteProcessingWithNewTemplate",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        template_id: templateIdToRegenerate,
      },
    });

    if (response.ok) {
      navigate(`/status/${response.value.note_id}`);
    }
  };

  const handleRequestAIEdit = async (
    sectionText: string,
    editInstructions: string,
    moduleId: string,
    setAwaitingEditTaskId: (taskId: string) => void
  ) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const url = "/notes/editSectionWithAI";
    const body = {
      section_content: sectionText,
      edit_instructions: editInstructions,
      module_id: moduleId,
      note_id: noteData.note_id,
    };

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: url,
        accessToken: accessToken,
        body: body,
      });

      if (response.ok) {
        setAwaitingEditTaskId(response.value.task_id);
      }
    } catch (error) {
      console.error("Failed to submit:", error);
    }

    // clarity.setTag("sessionType", "aiedit");
  };

  const fetchAdminEmails = async () => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIGetRequest({
      requestString: "/user/getAdminEmails",
      accessToken: accessToken,
    });

    if (response.ok) {
      const data = response.value;
      setEmailList(data.admin_emails);
    }
  };

  const handleOpenModal = () => {
    fetchAdminEmails();
    setEmailModalOpen(true);
  };

  const sendPDFAsEmail = async () => {
    if (sendPDFEmailList.length > 0) {
      setLoadingText("Sending");
      const accessToken = await getAccessToken();

      try {
        const response = await APIService.makeAPIPostRequest({
          requestString: "/email/sendPDFAsEmail",
          accessToken: accessToken,
          body: {
            note_id: noteId,
            email_list: sendPDFEmailList,
          },
        });

        if (response.ok) {
          const data = response.value;
          setSendPDFEmailList([]);
          setLoadingText("Sent");
          setTimeout(() => setLoadingText(""), 1000);
          setTimeout(() => setEmailModalOpen(false), 1000);
        } else {
          throw new Error("Failed to send PDF email.");
        }
      } catch (error) {
        console.error(error);
        setLoadingText("Sorry, could not send.");
        setTimeout(() => setLoadingText(""), 3000);
        setTimeout(() => setEmailModalOpen(false), 1000);
      }
    } else {
      setError("Please select at least one email.");
    }
  };

  const handleDeleteNote = async () => {
    if (demoMode) {
      setShowDeleteModal(false);
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/delete",
      accessToken: accessToken,
      body: { note_id: noteId },
    });

    if (response.ok) {
      const data = response.value;
      if (data.status === 200) {
        const win: Window = window;
        win.location = `/notes`;
      }
    }

    setShowDeleteModal(false);
  };

  const handleArchiveNote = async () => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/updateNoteReviewStatus",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        status: "archived",
      },
    });

    if (response.ok) {
      const data = response.value;
      if (data.status === 200) {
        const win: Window = window;

        if (origin === "group") {
          win.location = `/group`;
        } else {
          win.location = `/notes`;
        }
      }
    }
  };

  const handleReviewNote = async () => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/updateNoteCompletionStatus",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        completion_status: "reviewing",
      },
    });

    if (response.ok) {
      const data = response.value;
      if (data.status === 200) {
        window.location.reload();
      }
    }
  };

  const handleCompleteNote = async () => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/updateNoteCompletionStatus",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        completion_status: "completed",
      },
    });

    if (response.ok) {
      const data = response.value;
      if (data.status === 200) {
        window.location.reload();
      }
    }
  };

  const handleSubmitSectionRatingFeedback = async (
    section_id: string,
    rating: number,
    module_id: string
  ) => {
    if (!section_id) {
      return;
    }
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteSectionFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_id: section_id,
        section_rating: rating,
        module_id: module_id,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleSubmitSectionCommentFeedback = async (
    section_id: string,
    comment: string,
    module_id: string,
    ai_instruction_session_id: string
  ) => {
    if (!section_id) {
      return;
    }
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteSectionFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_id: section_id,
        section_comment: comment,
        module_id: module_id,
        ai_instruction_session_id: ai_instruction_session_id,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleSubmitNoteCommentFeedback = async (comment: string) => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        note_comment: comment,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleSubmitNoteRatingFeedback = async (rating: number) => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/addNoteFeedbackEvent",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        note_rating: rating,
      },
    });

    if (response.ok) {
      const data = response.value;
    }
  };

  const handleCloseNoteOnboarding = () => {
    setShowNoteOnboarding(false);
  };

  const updateNoteDataSections = (updatedSections: NoteSectionType[]) => {
    setNoteData((prevNoteData) => {
      // Create a copy of the current noteData
      let newNoteData = { ...prevNoteData };

      // Iterate over the updated sections and update the corresponding sections in noteData
      updatedSections.forEach((updatedSection) => {
        const sectionIndex = newNoteData.content.sections.findIndex(
          (section) => section.section_id === updatedSection.section_id
        );

        if (sectionIndex !== -1) {
          newNoteData.content.sections[sectionIndex] = updatedSection;
        }
      });

      return newNoteData;
    });
  };

  const updateNoteDataExtras = (updatedExtras: NoteExtraType[]) => {
    setNoteData((prevNoteData) => {
      // Create a copy of the current noteData
      let newNoteData = { ...prevNoteData };

      // Ensure content and extras exist
      newNoteData.content = newNoteData.content || {};
      newNoteData.content.extras = newNoteData.content.extras || [];

      // Iterate over the updated extras and update the corresponding extras in noteData
      if (
        Array.isArray(newNoteData.content.extras) &&
        newNoteData.content.extras.length > 0
      ) {
        updatedExtras.forEach((updatedExtra) => {
          const extraIndex = newNoteData.content.extras!.findIndex(
            (extra) => extra.name === updatedExtra.name
          );

          if (extraIndex !== -1) {
            newNoteData.content.extras![extraIndex] = updatedExtra;
          } else {
            newNoteData.content.extras!.push(updatedExtra);
          }
        });
      }

      return newNoteData;
    });
  };

  const [loadingAttempts, setLoadingAttempts] = useState(0);
  const MAX_LOADING_ATTEMPTS = 25;

  const getLoadingNoteSections = async (
    sectionIds: string[] = [],
    processingExtras: string[] = []
  ) => {
    if (sectionIds.length <= 0 && processingExtras.length <= 0) {
      return;
    }

    // Increment attempt counter
    setLoadingAttempts((prev) => {
      if (prev >= MAX_LOADING_ATTEMPTS) {
        // Clear the interval if we've exceeded max attempts
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = undefined;
        }
        return prev;
      }
      return prev + 1;
    });

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/notes/getNoteSectionsBySectionIds",
      accessToken: accessToken,
      body: {
        note_id: noteId,
        section_ids: sectionIds,
        extra_names: processingExtras,
      },
    });

    if (response.ok) {
      const data = response.value.data as {
        sections: NoteSectionType[];
        extras: NoteExtraType[];
      };
      if (data.sections && data.sections.length > 0) {
        updateNoteDataSections(data.sections);
      }
      if (data.extras && Object.keys(data.extras).length > 0) {
        updateNoteDataExtras(data.extras);
      }
    }
  };

  const [selectedWord, setSelectedWord] = useState<string | null>(null);

  const handleWordClick = (word: string) => {
    if (!troubleWords) {
      console.error("troubleWords is not defined");
      return;
    }

    const correctSpelling = troubleWords.find(
      (entry) =>
        entry.correct_spelling.toLowerCase() === word.toLowerCase() ||
        entry.misspellings.some(
          (misspelling) => misspelling.toLowerCase() === word.toLowerCase()
        )
    )?.correct_spelling;

    setSelectedWord(word);
    setOpenSpellingModal(true);
    // clarity.setTag("sessionType", "spellingmodal");
  };

  const formatMatchedWords = (words: string[]) => {
    return words.map((word, index) => (
      <React.Fragment key={index}>
        {index > 0 && (index === words.length - 1 ? " and " : ", ")}
        <span
          style={{ color: "#36229E", cursor: "pointer" }}
          onClick={() => handleWordClick(word)}
        >
          {word}
        </span>
      </React.Fragment>
    ));
  };

  const handleNewWordsSubmit = (
    correctWord: string,
    newMisspellings: string[]
  ) => {
    if (!troubleWords) {
      console.error("troubleWords is not defined");
      return;
    }

    const newTroubleWords = [...troubleWords];
    const existingEntryIndex = newTroubleWords.findIndex(
      (entry) => entry.correct_spelling === correctWord
    );

    if (existingEntryIndex === -1) {
      // If the correct word doesn't exist, add a new entry
      newTroubleWords.push({
        correct_spelling: correctWord,
        misspellings: newMisspellings,
      });
    } else {
      // If the correct word exists, update its misspellings
      newTroubleWords[existingEntryIndex] = {
        ...newTroubleWords[existingEntryIndex],
        misspellings: [
          ...new Set([
            ...newTroubleWords[existingEntryIndex].misspellings,
            ...newMisspellings,
          ]),
        ],
      };
    }

    // Update the troubleWords state
    //setTroubleWords(newTroubleWords);
  };

  const handleAssignSuccess = () => {
    window.location.reload();
  };
  const handleUnassignSuccess = () => {
    setPatientData(null);
  };

  const handleModuleCustomizationSession = (sectionIndex: number) => {
    const section = noteData.content.sections[sectionIndex];

    if (!section.module_id) {
      return;
    }

    setModuleCustomizationModalInfo((prevState) => ({
      ...prevState,
      showModal: true,
      module_id: section.module_id,
      note_id: noteId || "",
      initialComparisonText: section.edited_text
        ? section.edited_text
        : section.text,
      section_name: section.name,
      sectionIndex: sectionIndex,
    }));
  };

  const getRelevantMisspellings = (words: string[]) => {
    if (!troubleWords) {
      console.error("troubleWords is not defined");
      return {};
    }

    const relevantMisspellings: { [key: string]: string[] } = {};
    words.forEach((word) => {
      const lowerCaseWord = word.toLowerCase();
      const matchingEntry = troubleWords.find(
        (entry) =>
          entry.correct_spelling.toLowerCase() === lowerCaseWord ||
          entry.misspellings.some(
            (misspelling) => misspelling.toLowerCase() === lowerCaseWord
          )
      );

      if (matchingEntry) {
        relevantMisspellings[matchingEntry.correct_spelling] =
          matchingEntry.misspellings;
      }
    });

    return relevantMisspellings;
  };

  const highlightMisspelledWords = (
    text: string,
    misspelledWords: string[],
    onClick: (word: string) => void
  ) => {
    let highlightedText = text;

    misspelledWords.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi");
      highlightedText = highlightedText.replace(regex, (match) => {
        return `<span class="highlight" onclick="openSpellModal('${match}')">${match}</span>`;
      });
    });

    return highlightedText;
  };

  const handleHighlightClick = (word: string) => {
    setSelectedWord(word);
    setOpenSpellingModal(true);
  };

  useEffect(() => {
    if (scrollRef.current && activeSection) {
      // Find the associated content section in the main container
      const contentSection = scrollRef.current.querySelector(
        `#section-${activeSection.id}`
      );

      // Scroll the main container to the content section
      contentSection?.scrollIntoView({ behavior: "smooth", inline: "start" });
    }
  }, [activeSection]);

  // Helper function to escape special regex characters
  function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  const handleResubmitClaim = async () => {
    if (demoMode) {
      return;
    }

    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/billing/resubmitClaim",
      accessToken: accessToken,
      body: { note_id: noteId },
    });

    if (response.ok) {
      setBillingSubmitted(false); // Assuming this state controls the submit button visibility
      console.log("Claim resubmit allowed");
    } else {
      // Handle error
      console.error("Failed to resubmit claim");
      throw new Error("Failed to resubmit claim"); // Throw an error to indicate failure
    }
  };

  useEffect(() => {
    const emptyTextSectionIds: string[] = [];
    const processingExtras: string[] = [];
    const misspelledMatches: string[] = [];
    const correctSpellingMatches: string[] = [];

    if (!troubleWords) {
      console.warn("troubleWords is not defined");
      return;
    }

    noteData.content.sections.forEach((section) => {
      if (section.text === "") {
        emptyTextSectionIds.push(section.section_id);
      }

      const text = section.text;

      troubleWords.forEach((troubleWord) => {
        const correctSpelling = troubleWord.correct_spelling;
        troubleWord.misspellings.forEach((misspelling: string) => {
          // Use lookahead and lookbehind to match words bounded by spaces, punctuation, or start/end of string
          // Updated regex to include single and double quotes
          const regex = new RegExp(
            `(?<=^|\\s|[.,!?;:()\\[\\]\\-/'"])(${escapeRegExp(
              misspelling
            )})(?=\\s|[.,!?;:()\\[\\]\\-/'"]|$)`,
            "gi"
          );
          const matches = text.match(regex);
          if (
            matches &&
            !misspelledMatches.includes(misspelling.toLowerCase())
          ) {
            misspelledMatches.push(misspelling.toLowerCase());
            if (!correctSpellingMatches.includes(correctSpelling)) {
              correctSpellingMatches.push(correctSpelling);
            }
          }
        });
      });
    });

    // Check for processing extras
    if (noteData.content.extras) {
      noteData.content.extras.forEach((extra) => {
        if (extra.status === "processing") {
          processingExtras.push(extra.name);
        }
      });
    }

    setMatchedMisspelled(misspelledMatches);
    setMatchedWords(correctSpellingMatches);

    if (emptyTextSectionIds.length > 0 || processingExtras.length > 0) {
      if (!intervalRef.current && loadingAttempts < MAX_LOADING_ATTEMPTS) {
        intervalRef.current = setInterval(async () => {
          await getLoadingNoteSections(emptyTextSectionIds, processingExtras);
        }, 5000) as unknown as number;
      }
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    }
  }, [noteData, troubleWords, loadingAttempts]); // Add loadingAttempts to dependencies

  useEffect(() => {
    if (userState?.onboardingSteps && !demoMode) {
      if (!userState.onboardingSteps.viewed_first_note) {
        setShowTooltips(true);
        updateOnboardingStep("viewed_first_note", true);
      }
    }
  }, [userState]);

  const fetchPatientData = async () => {
    if (!noteData.patient_id) {
      setPatientData(null);
      return;
    }
    if (demoMode) {
      setPatientData(null);
      return;
    }

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/get?patient_id=${noteData.patient_id}`,
        accessToken: accessToken,
      });

      if (response.ok) {
        setPatientData(response.value.patient);
      } else {
        throw new Error("Failed to fetch patient data");
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      setPatientData(null);
    }
  };

  useEffect(() => {
    if (loadedInChromeExtension) {
      if (
        !localStorage.getItem("viewed_chrome_extension_tooltips") ||
        localStorage.getItem("viewed_chrome_extension_tooltips") !== "true"
      ) {
        localStorage.setItem("viewed_chrome_extension_tooltips", "true");
        setShowChromeExtensionTooltips(true);
      } else {
        setShowChromeExtensionTooltips(false);
      }
    }
  }, [loadedInChromeExtension]);

  useEffect(() => {
    const fetchPatientData = async () => {
      if (!noteData.patient_id) {
        setPatientData(null);
        return;
      }
      if (demoMode) {
        setPatientData(null);
        return;
      }

      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/patient/get?patient_id=${noteData.patient_id}`,
          accessToken: accessToken,
        });

        if (response.ok) {
          setPatientData(response.value.patient);
        } else {
          throw new Error("Failed to fetch patient data");
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
        setPatientData(null);
      }
    };

    fetchPatientData();
  }, [noteData.patient_id]);

  useEffect(() => {
    if (patientData) {
      const eligibilityRequiredFields = ["first_name", "last_name", "payer_id"];
      const billingRequiredFields = [
        "first_name",
        "last_name",
        "dob",
        "address",
        "city",
        "state",
        "zip",
        "gender",
        "payer_id",
        "payer_name",
        "payer_relationship",
        "insurance_policy_number",
      ];
      const missingEligibilityFields = eligibilityRequiredFields.filter(
        (field) => !patientData[field as keyof Patient]
      );
      const missingBillingFields = billingRequiredFields.filter(
        (field) => !patientData[field as keyof Patient]
      );
      setMissingPatientEligibilityFields(missingEligibilityFields);
      setMissingPatientBillingFields(missingBillingFields);
    }
  }, [patientData]);

  useEffect(() => {
    const eligibilityRequiredProviderFields = ["npi_number", "tax_id"];
    const billingRequiredProviderFields = [
      "npi_number",
      "first_name",
      "last_name",
      "facility_address",
      "state",
      "city",
      "zip",
      "phone",
      "tax_id",
      "tax_id_type",
      "accept_insurance_payment_as_full",
    ];
    const missingEligibilityFields = eligibilityRequiredProviderFields.filter(
      (field) =>
        !userState?.providerBillingInfo?.[
          field as keyof typeof userState.providerBillingInfo
        ]
    );
    const missingBillingFields = billingRequiredProviderFields.filter(
      (field) =>
        !userState?.providerBillingInfo?.[
          field as keyof typeof userState.providerBillingInfo
        ]
    );
    setMissingProviderEligibilityFields(missingEligibilityFields);
    setMissingProviderBillingFields(missingBillingFields);
  }, [userState?.providerBillingInfo]);

  const buttonStyle = {
    backgroundColor: "#4e54c8",
    color: "white",
    padding: "5px 5px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    fontSize: "10px",
    top: "70%",
  };

  const fieldNameMapping = {
    // Patient fields
    first_name: "First Name",
    last_name: "Last Name",
    identifier: "Patient ID",
    dob: "Date of Birth",
    address: "Address",
    phone: "Phone Number",
    gender: "Gender",

    // Provider fields
    npi_number: "NPI Number",
    facility_address1: "Facility Address",
    facility_address2: "Facility Address 2",
    state: "State",
    // place_of_service: "Place of Service",
  };

  // Helper function to get user-friendly field names
  const getUserFriendlyFieldName = (fieldName: string): string => {
    return (
      fieldNameMapping[fieldName as keyof typeof fieldNameMapping] || fieldName
    );
  };

  const billingCodeExtra = noteData.content.extras?.find(
    (extra) => extra.name === "billing_code"
  );

  const predictedCodesExtra = noteData.content.extras?.find(
    (extra) => extra.name === "billing_data_prediction"
  );
  return (
    <>
      <AssignPatientToNoteDialog
        open={openAssignPatientToNoteDialog}
        onClose={() => setOpenAssignPatientToNoteDialog(false)}
        noteId={noteId || ""}
        onAssignSuccess={handleAssignSuccess}
      />
      <EmailNoteModal
        emailModalOpen={emailModalOpen}
        setEmailModalOpen={setEmailModalOpen}
        sendPDFAsEmail={sendPDFAsEmail}
        setSendPDFEmailList={setSendPDFEmailList}
        loadingText={loadingText}
        error={error}
        setError={setError}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        continueText="Delete"
        cancelText="Cancel"
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onContinue={handleDeleteNote}
      >
        Are you sure you want to delete this note? This action cannot be undone.
      </DeleteModal>
      <SpellModal
        open={openSpellingModal}
        onClose={() => {
          setOpenSpellingModal(false);
          setSelectedWord(null);
        }}
        matchedWords={matchedWords}
        misspelledWords={matchedMisspelled}
        selectedWord={selectedWord}
        onSubmit={handleSpellingModalSubmit}
        onNewWordsSubmit={handleNewWordsSubmit}
        troubleWords={troubleWords}
        addTroubleWords={addTroubleWords}
      />
      <Modal
        open={moduleCustomizationModalInfo.showModal}
        onClose={() => {
          setModuleCustomizationModalInfo((prevState) => ({
            ...prevState,
            showModal: false,
          }));
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
            borderRadius: 8,
            width: "100%",
            maxWidth: "90vw",
            height: "100%",
            maxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ModuleCustomizationView
              note_id={moduleCustomizationModalInfo.note_id}
              module_id={moduleCustomizationModalInfo.module_id}
              initialComparisonText={
                moduleCustomizationModalInfo.initialComparisonText
              }
              section_name={moduleCustomizationModalInfo.section_name}
              onClose={() => {
                setModuleCustomizationModalInfo((prevState) => ({
                  ...prevState,
                  showModal: false,
                }));
              }}
              onAccept={(generation_output: string) => {
                handleSaveEdits(
                  moduleCustomizationModalInfo.sectionIndex,
                  generation_output
                );
              }}
            />
            <CloseButton
              onClick={() => {
                setModuleCustomizationModalInfo((prevState) => ({
                  ...prevState,
                  showModal: false,
                }));
              }}
            />
          </Box>
        </Box>
      </Modal>
      <SlideSidePane
        open={isBillingSidePaneOpen}
        onClose={handleCloseBillingSidePane}
        title="Submit Billing"
      >
        <BillingSubmissionView
          billingCodeExtra={billingCodeExtra?.data}
          providerBillingInfo={userState?.providerBillingInfo}
          patient={patientData}
          note={noteData}
          noteId={noteId}
          missingPatientBillingFields={missingPatientBillingFields}
          missingProviderBillingFields={missingProviderBillingFields}
          onBillingSubmitted={handleBillingSubmitted}
          onBillingCodeUpdate={(newBillingCode: string) => {
            return Promise.resolve();
          }}
        />
      </SlideSidePane>
      <SlideSidePane
        open={isPatientInformationSidePaneOpen}
        onClose={handleClosePatientInformationSidePane}
        title="Patient Information"
      >
        <PatientInformationView
          patientId={patientData?.patient_id!}
          highlightedFields={missingPatientBillingFields}
          onPatientSave={() => {
            fetchPatientData();
            handleClosePatientInformationSidePane();
          }}
        />
      </SlideSidePane>
      <SlideSidePane
        open={isProviderInformationSidePaneOpen}
        onClose={handleCloseProviderInformationSidePane}
        title="Provider Information"
      >
        <ProviderInformationView
          onProviderSave={() => {
            fetchUserInfo();
            handleCloseProviderInformationSidePane();
          }} // TODO: Refresh user info
          highlightedFields={missingProviderBillingFields}
        />
      </SlideSidePane>
      <SlideSidePane
        open={isPatientEligibilitySidePaneOpen}
        onClose={handleClosePatientEligibilitySidePane}
        title="Patient Eligibility"
      >
        <PatientEligibilityView
          onPatientEligibilitySave={handlePatientEligibilitySave}
          initialPatRel={patRel}
          billingCodeInfo={billingCodeExtra?.data.billing_code}
          providerBillingInfo={userState?.providerBillingInfo}
          patient={patientData}
          note={noteData}
        />
      </SlideSidePane>
      <LayoutWrapper>
        <LayoutWrapper.TopBar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                minWidth: "max-content",
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  flexDirection: "row",
                  alignItems: "center",
                  ml: 4,
                  order: 0,
                }}
              >
                <Typography
                  variant="body2"
                  onClick={() =>
                    setUIState({
                      ...uiState,
                      sidebar: { isHidden: !uiState.sidebar.isHidden },
                    })
                  }
                  sx={{
                    color: "primary.main",
                    textDecoration: "none",
                    cursor: "pointer",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  {uiState.sidebar.isHidden ? (
                    <>
                      <MenuIcon />
                      <KeyboardArrowRightOutlined />
                    </>
                  ) : (
                    <>
                      <MenuIcon />
                      <KeyboardArrowLeftOutlined />
                    </>
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  ml: { xs: 0, md: 4 },
                  order: { xs: 2, md: 1 },
                }}
              >
                {selectedSections.length > 0 && (
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      fontSize: "0.85rem",
                      bgcolor: "backgroundColors.secondary",
                      borderColor: "borderColors.lightprimary",
                      border: "0.1px solid",
                      fontWeight: "700",
                      px: 2,
                    }}
                    onClick={() => setSelectedSections([])}
                  >
                    Deselect All
                  </RoundedButton>
                )}

                <RoundedButton
                  variant="outlined"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "700",
                    whiteSpace: "nowrap",
                    bgcolor: "backgroundColors.secondary",
                    borderColor: "borderColors.lightprimary",
                    border: "0.1px solid",
                    px: 2,
                  }}
                  onClick={handleOpenSpellingModal}
                >
                  <SpellcheckIcon
                    sx={{
                      color: theme.palette.primary.light,
                      fontSize: "1rem",
                      paddingRight: 0.25,
                    }}
                  />
                  Check Spellings
                </RoundedButton>

                <ConditionalTooltip
                  title="This button copies all of the 'selected' sections to your clipboard. Press the + icon next to a section to copy it."
                  condition={showTooltips}
                  placement={"top"}
                  arrow
                >
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      px: 2,
                      bgcolor: "backgroundColors.secondary",
                      borderColor: "borderColors.lightprimary",
                      border: "0.1px solid",
                    }}
                    onClick={() => handleCopySections(false)}
                  >
                    <ContentCopyIcon
                      sx={{
                        color: theme.palette.primary.light,
                        fontSize: "1rem",
                        paddingRight: 0.25,
                      }}
                    />
                    Copy Selected Sections
                  </RoundedButton>
                </ConditionalTooltip>

                <ConditionalTooltip
                  title="This button automatically copies all sections of the note to your clipboard."
                  condition={showTooltips}
                  placement={"top"}
                  arrow
                >
                  <RoundedButton
                    variant="outlined"
                    sx={{
                      fontSize: "0.85rem",
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      px: 2,
                      bgcolor: "backgroundColors.secondary",
                      borderColor: "borderColors.lightprimary",
                      border: "0.1px solid",
                    }}
                    onClick={() => handleCopySections(true)}
                  >
                    <ContentCopyIcon
                      sx={{
                        color: theme.palette.primary.light,
                        fontSize: "1rem",
                        paddingRight: 0.25,
                      }}
                    />
                    Copy All Sections
                  </RoundedButton>
                </ConditionalTooltip>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mr: { xs: 0, md: 4 },
                  order: { xs: 1, md: 2 },
                }}
              >
                <ConditionalTooltip
                  title="Switch between the 'note' and the 'transcript' with this button."
                  condition={showTooltips}
                  placement={"top"}
                  arrow
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <RoundedButton
                      variant="outlined"
                      sx={{
                        fontSize: "0.85rem",
                        fontWeight: "700",
                        whiteSpace: "nowrap",
                        px: 2,
                        bgcolor: "backgroundColors.secondary",
                        borderColor: "borderColors.lightprimary",
                        border: "0.1px solid",
                      }}
                      onClick={() => {
                        if (viewMode.mode === "note") {
                          setViewMode({ mode: "transcript" });
                        } else {
                          setViewMode({ mode: "note" });
                        }
                      }}
                    >
                      {viewMode.mode === "note"
                        ? "View Transcript"
                        : "View Note"}
                    </RoundedButton>
                  </Box>
                </ConditionalTooltip>
              </Box>
            </Box>
          </Box>
        </LayoutWrapper.TopBar>
        <LayoutWrapper.LeftSidebar>
          <Box className="flex-1" sx={removeScrollBar}>
            <Typography
              align="center"
              sx={{
                fontWeight: "bold",
                my: 2,
                pt: 2,
                color: "textColors.primaryText",
              }}
            >
              Note Content
            </Typography>
            <ul>
              {noteData.content &&
                noteData.content.sections.map((section, sectionIndex) => {
                  const isSelected = selectedSections.includes(sectionIndex);
                  return (
                    <ListItem key={sectionIndex} sx={{ width: "100%" }}>
                      <ConditionalTooltip
                        title="An overview of all of the sections of the note. Click this to automatically scroll to that section."
                        condition={showTooltips}
                        placement={"right"}
                        arrow
                      >
                        <Box
                          onClick={() => handleSectionClick(sectionIndex)}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            px: 1,
                            fontSize: "text-base",
                            color: "textColors.darkHeader",
                            borderRadius: "lg",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Box
                            onClick={(event) =>
                              handleSidebarIconClick(event, sectionIndex)
                            }
                          >
                            <SVGIcon
                              name={
                                isSelected ? "check-filled" : "check-unfilled"
                              }
                            />
                          </Box>
                          <Typography
                            variant="body1"
                            component="span"
                            color="backgroundColors.brand"
                            sx={{
                              ml: 3,
                              textTransform: "capitalize",
                              fontStyle: "normal",
                              fontWeight: isSelected ? "bold" : "normal",
                            }}
                          >
                            {/* Remove any text between parentheses, e.g. (Customized) */}
                            {section.name?.replace(/\s*\([^)]*\)/g, "")}
                          </Typography>
                        </Box>
                      </ConditionalTooltip>
                    </ListItem>
                  );
                })}
            </ul>
          </Box>
        </LayoutWrapper.LeftSidebar>
        <LayoutWrapper.MainContent>
          <Box className="flex-1">
            {noteData.content && (
              <Box sx={{ my: 2, mx: 4 }}>
                <Box className="mb-4 flex flex-col md:flex-row">
                  <Box className="flex-auto">
                    <Box sx={{ mb: 0.5, ml: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 0.5,
                        }}
                        onDoubleClick={() =>
                          !isEditingTitle && setIsEditingTitle(true)
                        }
                      >
                        {isEditingTitle ? (
                          <>
                            <TextField
                              value={editedTitle}
                              onChange={(e) => setEditedTitle(e.target.value)}
                              variant="standard"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontSize: "3rem",
                                  fontWeight: 600,
                                  color: theme.palette.text.primary,
                                },
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleUpdateTitle();
                                }
                              }}
                            />
                            <IconButton
                              onClick={handleUpdateTitle}
                              size="small"
                              sx={{ ml: 1 }}
                            >
                              <CheckIcon />
                            </IconButton>
                          </>
                        ) : (
                          <Tooltip
                            title="Double click to edit title"
                            arrow
                            placement="right"
                          >
                            <Typography
                              variant="h3"
                              sx={{
                                color: "textColors.darkHeader",
                                fontWeight: "600",
                                fontSize: {
                                  xs: "1.5rem",
                                  sm: "2rem",
                                  md: "2.5rem",
                                  lg: "3rem",
                                },
                              }}
                            >
                              {noteData.note_title}
                            </Typography>
                          </Tooltip>
                        )}
                      </Box>
                      {!demoMode && (
                        <>
                          {patientData === undefined ? (
                            <Skeleton variant="text" width={200} height={30} />
                          ) : patientData ? (
                            <PatientUnassignment
                              patientData={{
                                patient_id: patientData.patient_id ?? "",
                                first_name: patientData.first_name ?? "",
                                last_name: patientData.last_name ?? "",
                              }}
                              noteId={noteId || ""}
                              onUnassignSuccess={handleUnassignSuccess}
                            />
                          ) : (
                            <Box sx={{ mt: 1, mb: 2 }}>
                              <AssignNoteToPatient
                                noteId={noteId || ""}
                                onAssignSuccess={handleAssignSuccess}
                              />
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                    <Box sx={{ mb: 2, ml: 2 }}>
                      <NoteMetadata noteData={noteData} />
                    </Box>
                    <Divider />
                    {matchedWords.length > 0 && (
                      <ConditionalTooltip
                        title="Boxes like this will show up if there is something in the note's content that we would like to draw your attention to."
                        condition={showTooltips}
                        placement={"left"}
                        arrow
                      >
                        <Box
                          sx={{
                            bgcolor: "#FFDBDA",
                            border: 1,
                            borderColor: "#E66260",
                            borderRadius: "0.75rem",
                            padding: "1rem 1.5rem",
                            width: "100%",
                            marginTop: "1.5rem",
                            position: "relative",
                          }}
                        >
                          {/* Bell emoji with absolute positioning */}
                          <span
                            style={{
                              position: "absolute",
                              top: "-10px",
                              left: "-10px",
                              fontSize: "24px",
                              // backgroundColor: '#EC8583',
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "5px",
                              paddingRight: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "30px",
                              height: "30px",
                              boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                            }}
                          >
                            👋
                          </span>
                          <Box>
                            {" "}
                            We've noticed{" "}
                            {formatMatchedWords([...matchedWords])} in your
                            note. Our software might confuse{" "}
                            {matchedWords.length === 1 ? "this" : "these"} with
                            similar medication names, so please take a moment to
                            double-check{" "}
                            {matchedWords.length === 1 ? "it" : "them"} for
                            accuracy. As always, leave feedback if you find any
                            mistakes. Thank you!
                          </Box>
                          <Box sx={{ mt: 1 }}>
                            <Button
                              style={buttonStyle}
                              onClick={() => {
                                setOpenSpellingModal(true);
                                // clarity.setTag("sessionType", "spellingmodal");
                              }}
                            >
                              Review Medications
                            </Button>
                            <SpellModal
                              open={openSpellingModal}
                              onClose={() => {
                                setOpenSpellingModal(false);
                                setSelectedWord(null);
                              }}
                              matchedWords={matchedWords}
                              misspelledWords={matchedMisspelled}
                              selectedWord={selectedWord}
                              onSubmit={handleSpellingModalSubmit}
                              onNewWordsSubmit={handleNewWordsSubmit}
                              troubleWords={troubleWords}
                              // getTroubleWords={getTroubleWords}
                              addTroubleWords={addTroubleWords}
                            />
                          </Box>
                        </Box>
                      </ConditionalTooltip>
                    )}
                    {/* If summary exists, to be phased out in future versions of app */}
                    {noteData.content.summary && (
                      <Typography
                        variant="body1"
                        sx={{ color: "textColors.primaryText" }}
                      >
                        {textToParagraph({ text: noteData.content.summary })}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {viewMode.mode === "note" && (
                  <Box>
                    {noteData.content &&
                      noteData.content.sections.map((section, sectionIndex) => {
                        return (
                          <Box
                            key={sectionIndex}
                            id={`section-${sectionIndex}`}
                            style={{ scrollMarginTop: "4em" }}
                          >
                            <NoteSection
                              onWordClick={handleWordClick}
                              showTooltips={showTooltips}
                              heading={section.name}
                              text={
                                section.edited_text
                                  ? section.edited_text
                                  : section.text
                              }
                              sectionId={section.section_id}
                              moduleId={section.module_id}
                              isEdited={section.edited_text ? true : false}
                              isAdded={selectedSections.includes(sectionIndex)}
                              handleAdd={() => handleAddSection(sectionIndex)}
                              isRated={section.is_rated ? true : false}
                              isErrored={section.error ? true : false}
                              context={section.context}
                              handleSaveEdits={(editedText) => {
                                handleSaveEdits(sectionIndex, editedText);
                              }}
                              handleRevertEdits={() => {
                                handleRevertEdits(sectionIndex);
                              }}
                              isSaving={saving.includes(sectionIndex)}
                              isEditable={
                                noteData.completion_status !== "completed"
                              }
                              handleRegenerateSection={handleRegenerateSection}
                              handleRequestAIEdit={handleRequestAIEdit}
                              handleSectionRatingFeedback={(rating: number) =>
                                handleSubmitSectionRatingFeedback(
                                  section.section_id,
                                  rating,
                                  section.module_id
                                )
                              }
                              handleSectionCommentFeedback={(
                                comment: string,
                                ai_instruction_session_id: string
                              ) => {
                                handleSubmitSectionCommentFeedback(
                                  section.section_id,
                                  comment,
                                  section.module_id,
                                  ai_instruction_session_id
                                );
                              }}
                              handleSectionSetCopyStatus={() =>
                                handleSetCopyStatus()
                              }
                              handleSectionSendCopyEvents={(
                                module_id: string
                              ) => handleSendCopyEvents([module_id], false)}
                              handleStartModuleCustomizationSession={() => {
                                handleModuleCustomizationSession(sectionIndex);
                              }}
                              setTutorialText={(
                                headerText: string,
                                description: string
                              ) => {
                                setTutorialHeaderText(headerText);
                                setTutorialDescription(description);
                              }}
                              showChromeExtensionTooltips={
                                showChromeExtensionTooltips
                              }
                            />
                          </Box>
                        );
                      })}
                  </Box>
                )}
                {viewMode.mode === "transcript" && noteData.transcript && (
                  <Transcript transcript={noteData.transcript} />
                )}
                {!note?.is_rated && (
                  <NoteFeedbackBox
                    handleNoteCommentFeedback={(comment: string) => {
                      handleSubmitNoteCommentFeedback(comment);
                    }}
                    handleNoteRatingFeedback={(rating: number) => {
                      handleSubmitNoteRatingFeedback(rating);
                    }}
                  />
                )}
                {debug && noteData && (
                  <pre className="text-black/40">
                    {JSON.stringify(noteData.config, null, 4)}
                  </pre>
                )}
              </Box>
            )}
          </Box>
        </LayoutWrapper.MainContent>
        <LayoutWrapper.RightSidebar>
          <ContentCard
            icon={<DrawOutlinedIcon fontSize="medium" sx={{ mr: 2 }} />}
            title="Current template"
            sx={
              isSwitchTemplateCard
                ? {
                    minHeight: "420px",
                  }
                : {
                    minHeight: "140px",
                  }
            }
            content={
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      color: "textColors.brand",
                      textDecorationColor: "inherit",
                    }}
                  >
                    {noteData.template && noteData.template.template_name}
                  </Typography>
                  <Button
                    sx={{
                      minWidth: "auto",
                      textTransform: "none",
                      textDecoration: "underline",
                      color: "textColors.darkText",
                      "&:hover": {
                        backgroundColor: "inherit",
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() =>
                      setIsSwitchTemplateCard(!isSwitchTemplateCard)
                    }
                  >
                    Switch Template
                  </Button>
                </Box>
                <Collapse in={isSwitchTemplateCard}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "textColors.primary", mb: 2 }}
                  >
                    Would you like to regenerate this note with a different
                    template?
                  </Typography>
                  <TemplatePicker
                    onTemplateSelect={(template_id) => {
                      setTemplateIdToRegenerate(template_id);
                    }}
                  />
                  <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <RoundedButton
                      variant="outlined"
                      sx={{ color: "textColors.brand" }}
                      onClick={() => {
                        setIsSwitchTemplateCard(false);
                      }}
                    >
                      Cancel
                    </RoundedButton>
                    <RoundedButton
                      variant="outlined"
                      sx={{ color: "textColors.brand" }}
                      onClick={() => {
                        handleRegenerateNoteWithNewTemplate();
                      }}
                      disabled={!templateIdToRegenerate}
                    >
                      Regenerate
                    </RoundedButton>
                  </Box>
                  <Typography
                    variant="caption"
                    color={templateIdToRegenerate ? "red" : "textSecondary"}
                    sx={{ mt: 4, alignSelf: "flex-end" }}
                  >
                    Regenerating is permanent, all content and edits made to
                    this note will be lost.
                  </Typography>
                </Collapse>
              </>
            }
          />
          {userState?.featureFlags?.billing_data_collection &&
            !userState?.featureFlags?.billing_data_prediction && (
              <BillingSidebarDataCollection
                noteId={noteId || ""}
                billingSubmitted={note?.billing_data_collected}
              />
            )}
          {userState?.featureFlags?.billing_data_prediction &&
            predictedCodesExtra && (
              <BillingSidebarDataPrediction
                noteId={noteId || ""}
                billingSubmitted={note?.billing_data_collected}
                status={predictedCodesExtra.status}
                initialPredictedCodes={predictedCodesExtra.data}
              />
            )}
          <DismissableCard
            headerTitle={tutorialHeaderText}
            bodyText={[tutorialDescription]}
          />
          {/* {billingCodeExtra && userState?.featureFlags?.billing_feature && (
            <BillingSidebar
              billingCodeExtra={billingCodeExtra}
              patientData={patientData}
              userState={userState}
              showTooltips={showTooltips}
              billingSubmitted={billingSubmitted}
              handleProviderInfoClick={handleProviderInfoClick}
              handlePatientInfoClick={handlePatientInfoClick}
              handlePatientEligibilityClick={handlePatientEligibilityClick}
              handleOpenBillingSidePane={handleOpenBillingSidePane}
              handleAssignPatientClick={handleAssignPatientClick}
              handleResubmitClaim={handleResubmitClaim}
            />
          )}
          {billingCodeExtra && userState?.featureFlags?.billing_test_gather && (
            <TestGatherBillingSidebarWithExtra
              billingCodeExtra={billingCodeExtra}
              patientData={patientData}
              userState={userState}
              showTooltips={showTooltips}
              billingSubmitted={billingSubmitted}
              handleProviderInfoClick={handleProviderInfoClick}
              handlePatientInfoClick={handlePatientInfoClick}
              handlePatientEligibilityClick={handlePatientEligibilityClick}
              handleOpenBillingSidePane={handleOpenBillingSidePane}
              handleAssignPatientClick={handleAssignPatientClick}
              handleResubmitClaim={handleResubmitClaim}
              noteId={noteId || ""}
            />
          )}
          {!billingCodeExtra && userState && (
            <>
              <TestGatherBillingSidebar
                userState={userState}
                noteId={noteId || ""}
              />
            </>
          )} */}
        </LayoutWrapper.RightSidebar>
        <LayoutWrapper.BottomBar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                minWidth: "max-content", // Ensure content doesn't wrap
                mr: 4,
              }}
            >
              <ConditionalTooltip
                title={`When you delete a note, ${brandLanguage.brandName} permanently deletes it and all associated data.`}
                condition={showTooltips}
                placement={"top"}
                arrow
              >
                <RoundedButton
                  variant="contained"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "700",
                    px: 2,
                    color: "textColors.brandContrast",
                    borderColor: "borderColors.warning",
                    bgcolor: "backgroundColors.warningDark",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      bgcolor: "backgroundColors.warningDark",
                      borderColor: "borderColors.warning",
                    },
                  }}
                  onClick={() => setShowDeleteModal(true)}
                >
                  <DeleteIcon
                    sx={{
                      color: "textColors.brandContrast",
                      fontSize: "1rem",
                      mr: 1,
                    }}
                  />
                  Delete Note
                </RoundedButton>
              </ConditionalTooltip>
              <ConditionalTooltip
                title={
                  noteData.completion_status === "completed"
                    ? "Review and edit this completed note"
                    : "When you're done editing a note, you can mark it as complete."
                }
                condition={showTooltips}
                placement={"top"}
                arrow
              >
                <RoundedButton
                  variant="contained"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "700",
                    whiteSpace: "nowrap",
                    px: 2,
                  }}
                  color="primary"
                  onClick={
                    noteData.completion_status === "completed"
                      ? handleReviewNote
                      : handleCompleteNote
                  }
                >
                  {noteData.completion_status === "completed" ? (
                    <>
                      <Edit
                        sx={{
                          fontSize: "1rem",
                          mr: 1,
                        }}
                      />
                      Review and Edit
                    </>
                  ) : (
                    <>
                      <Check
                        sx={{
                          fontSize: "1rem",
                          mr: 1,
                        }}
                      />
                      Complete Note
                    </>
                  )}
                </RoundedButton>
              </ConditionalTooltip>
            </Box>
          </Box>
        </LayoutWrapper.BottomBar>
      </LayoutWrapper>
    </>
  );
}

export default NoteViewRevamp;

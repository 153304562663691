import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Tooltip,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
} from "recharts";
import BaseBlock from "./BaseBlock";
import { format } from "date-fns";
import { Send as SendIcon, Add as AddIcon } from "@mui/icons-material";
import { useUser } from "../../../context/user";
import APIService from "../../../services/APIService";
import { useSnackbar } from "notistack";
import { Patient } from "../../../types/types";

interface TestScore {
  date: string;
  score: number;
  notes?: string;
  interpretation?: string;
}

interface TestMetadata {
  form_id?: string;
  form_type: string;
  grading_matrix: {
    ranges: {
      min: number;
      max: number;
      interpretation: string;
    }[];
  };
}

interface TestModuleData {
  current_data: {
    data?: TestScore[];
  };
  form_metadata: TestMetadata;
  type: "test";
  patient_module_id: string;
}

interface TestBlockProps {
  moduleData: TestModuleData;
  title: string;
  patientId: string;
  patient?: Patient;
}

const getScoreSeverity = (score: number, metadata: TestMetadata) => {
  const interpretation = metadata.grading_matrix.ranges.find(
    (range) => score >= range.min && score <= range.max
  );
  return {
    severity: interpretation?.interpretation || "Unknown",
    description: "No interpretation available",
  };
};

const TestBlock: React.FC<TestBlockProps> = ({
  moduleData,
  title,
  patientId,
  patient,
}) => {
  const metadata = moduleData.form_metadata;
  const scores = moduleData.current_data.data || [];
  const { getAccessToken } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { userState } = useUser();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [newScore, setNewScore] = useState("");
  const [newDate, setNewDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const isBlocked = !patient?.dob;

  const handleSendForm = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/assignForms",
        accessToken,
        body: {
          patient_id: patientId,
          form_ids: [metadata.form_id],
        },
      });

      if (response.ok) {
        enqueueSnackbar("Form sent successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error sending form:", error);
      enqueueSnackbar("Failed to send form", { variant: "error" });
    }
  };

  const handleAddScore = async () => {
    const scoreNum = parseFloat(newScore);
    if (isNaN(scoreNum)) {
      enqueueSnackbar("Please enter a valid score", { variant: "error" });
      return;
    }

    const newScoreEntry: TestScore = {
      date: new Date(newDate).toISOString(),
      score: scoreNum,
    };

    const dataKey = Object.keys(moduleData.current_data)[0];
    const updatedScores = [
      ...(moduleData.current_data.data || []),
      newScoreEntry,
    ];

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/updatePatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleData.patient_module_id,
          data: { data: updatedScores },
        },
      });

      if (response.ok) {
        moduleData.current_data.data = updatedScores;
        setIsAddDialogOpen(false);
        setNewScore("");
        setNewDate(format(new Date(), "yyyy-MM-dd"));
        enqueueSnackbar("Score added successfully", { variant: "success" });
      } else {
        throw new Error("Failed to add score");
      }
    } catch (error) {
      console.error("Error adding score:", error);
      enqueueSnackbar("Failed to add score", { variant: "error" });
    }
  };

  // Sort scores by date before using them
  const sortedScores = useMemo(() => {
    return [...scores].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }, [scores]);

  // Update to use sortedScores instead of scores
  const latestScore = useMemo(
    () => sortedScores[sortedScores.length - 1],
    [sortedScores]
  );
  const recentScores = useMemo(
    () =>
      sortedScores.slice(-12).map((score) => ({
        ...score,
        formattedDate: format(new Date(score.date), "MMM d"),
      })),
    [sortedScores]
  );

  const severity = useMemo(
    () => (latestScore ? getScoreSeverity(latestScore.score, metadata) : null),
    [latestScore, metadata]
  );

  return (
    <BaseBlock
      title={title}
      actions={
        userState?.featureFlags?.forms && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setIsAddDialogOpen(true)}
            >
              Add Score
            </Button>
            {metadata.form_id && (
              <Tooltip
                title={
                  isBlocked
                    ? "Patient date of birth is required to send forms"
                    : ""
                }
                arrow
              >
                <span>
                  {" "}
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<SendIcon />}
                    onClick={handleSendForm}
                    disabled={isBlocked}
                  >
                    Send
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        )
      }
    >
      {scores.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
          }}
        >
          <Typography variant="body1" color="text.secondary">
            No scores yet
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: "flex", gap: 2, height: 250 }}>
          {/* Left Column: Score Card + Legend */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: 200,
            }}
          >
            {/* Latest Score Card */}
            <Card>
              <CardContent
                sx={{
                  py: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Latest Score
                </Typography>
                <Typography variant="h4" component="div">
                  {latestScore.score}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {format(new Date(latestScore.date), "MMM d, yyyy")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Severity: {severity?.severity}
                </Typography>
              </CardContent>
            </Card>
          </Box>

          {/* Score History Graph */}
          <Box sx={{ flex: 1 }}>
            <ResponsiveContainer>
              <LineChart
                data={recentScores}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="formattedDate" tick={{ fontSize: 12 }} />
                <YAxis
                  domain={[
                    metadata.grading_matrix.ranges[0].min,
                    metadata.grading_matrix.ranges[
                      metadata.grading_matrix.ranges.length - 1
                    ].max,
                  ]}
                  tick={{ fontSize: 12 }}
                />
                <ChartTooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <Card sx={{ p: 1, bgcolor: "background.paper" }}>
                          <Typography variant="body2">
                            Date: {format(new Date(data.date), "MMMM d, yyyy")}
                          </Typography>
                          <Typography variant="body2">
                            Score: {data.score}
                          </Typography>
                          {data.notes && (
                            <Typography variant="body2">
                              Note: {data.notes}
                            </Typography>
                          )}
                        </Card>
                      );
                    }
                    return null;
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="score"
                  stroke="#8884d8"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      )}

      <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
        <DialogTitle>Add New Score</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}>
            <TextField
              label="Score"
              type="number"
              value={newScore}
              onChange={(e) => setNewScore(e.target.value)}
              inputProps={{
                min: metadata.grading_matrix.ranges[0].min,
                max: metadata.grading_matrix.ranges[
                  metadata.grading_matrix.ranges.length - 1
                ].max,
              }}
              fullWidth
            />
            <TextField
              label="Date"
              type="date"
              value={newDate}
              onChange={(e) => setNewDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddScore} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </BaseBlock>
  );
};

export default TestBlock;

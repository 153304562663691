import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Skeleton,
} from "@mui/material";
import BaseBlock from "./BaseBlock";

export interface Column<T> {
  id: keyof T | string;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: any) => string | React.ReactNode;
}

interface ListBlockProps<T> {
  title: string;
  columns: Column<T>[];
  data: T[];
  loading?: boolean;
  actions?: React.ReactNode;
  onRowClick?: (row: T) => void;
  rowsPerPageOptions?: number[];
  page?: number;
  rowsPerPage?: number;
  totalRows?: number;
  onPageChange?: (newPage: number) => void;
  onRowsPerPageChange?: (newRowsPerPage: number) => void;
}

function ListBlock<T>({
  title,
  columns,
  data,
  loading = false,
  actions,
  onRowClick,
  rowsPerPageOptions = [5, 10, 25],
  page = 0,
  rowsPerPage = 5,
  totalRows,
  onPageChange,
  onRowsPerPageChange,
}: ListBlockProps<T>) {
  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange?.(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onRowsPerPageChange?.(parseInt(event.target.value, 10));
  };

  return (
    <BaseBlock title={title} actions={actions}>
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={String(column.id)}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell key={String(column.id)}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : data.map((row, index) => (
                  <TableRow
                    hover
                    onClick={() => onRowClick?.(row)}
                    key={index}
                    sx={{ cursor: onRowClick ? "pointer" : "default" }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id as keyof T];
                      return (
                        <TableCell key={String(column.id)} align={column.align}>
                          {column.format ? column.format(value) : String(value)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            {!loading && data.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <Typography variant="body2" color="text.secondary">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {onPageChange && onRowsPerPageChange && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalRows || data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </BaseBlock>
  );
}

export default ListBlock;

// Add this type declaration at the top of the file
declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface GtagEvent {
  event: string;
  [key: string]: any;
}

export const trackEvent = (event: GtagEvent): void => {
  const win: Window = window;

  // Initialize dataLayer if it doesn't exist
  win.dataLayer = win.dataLayer || [];

  // Push the event to dataLayer with standardized structure
  if (import.meta.env.VITE_BUILD_ENV === "dev") {
    console.group("📊 DataLayer Event");
    console.log("Event Type:", event.event);
    console.log("Event Data:", event);
    console.groupEnd();
  }

  win.dataLayer.push({
    timestamp: new Date().toISOString(),
    ...event,
  });
};

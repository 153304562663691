import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Chip,
  Box,
  Card,
  CardContent,
  Typography,
  Pagination,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import ListBlock, { Column } from "./ListBlock";
import { useUser } from "../../../context/user";
import APIService from "../../../services/APIService";
import SlideSidePane from "../../../Views/Shared/SlideSidePane";
import { FormDefinition, FormDetail, Patient } from "../../../types/types";
import debounce from "lodash/debounce";
import { formatDate } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import FormSubmissionView from "../../Forms/FormSubmissionView";
import { useSnackbar } from "notistack";

interface Form {
  id: string;
  name: string;
  status: "pending" | "in_progress" | "completed";
  assigned_date: string;
  form_session_id: string;
}

interface AvailableForm {
  id: string;
  name: string;
  description: string;
}

interface FormsListBlockProps {
  patientId: string;
  patient?: Patient;
}

interface FormListResponse {
  forms: FormDefinition[];
  pagination: {
    total: number;
    skip: number;
    limit: number;
  };
}

const FormsListBlock: React.FC<FormsListBlockProps> = ({
  patientId,
  patient,
}) => {
  const [forms, setForms] = useState<Form[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const { getAccessToken } = useUser();
  const [isFormSelectorOpen, setIsFormSelectorOpen] = useState(false);
  const [availableForms, setAvailableForms] = useState<FormDefinition[]>([]);
  const [selectedFormIds, setSelectedFormIds] = useState<string[]>([]);
  const [formListPage, setFormListPage] = useState(1);
  const [totalFormPages, setTotalFormPages] = useState(1);
  const FORMS_PER_PAGE = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedForm, setSelectedForm] = useState<Form | null>(null);
  const [isFormDetailsOpen, setIsFormDetailsOpen] = useState(false);
  const [formDetail, setFormDetail] = useState<FormDetail | null>(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const columns: Column<Form>[] = [
    { id: "title", label: "Form Name", minWidth: 170 },
    {
      id: "status",
      label: "Status",
      minWidth: 100,
      format: (value: string) => (
        <Chip
          label={
            value === "in_progress"
              ? "In Progress"
              : value.charAt(0).toUpperCase() + value.slice(1)
          }
          color={
            value === "completed"
              ? "success"
              : value === "in_progress"
              ? "primary"
              : "warning"
          }
          size="small"
        />
      ),
    },
    {
      id: "created_timestamp",
      label: "Assigned Date",
      minWidth: 100,
      format: (value: number) => formatDate(value),
    },
  ];

  const fetchForms = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/forms?patient_id=${patientId}&skip=${
          page * rowsPerPage
        }&limit=${rowsPerPage}`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setForms(data.forms);
        setTotalRows(data.total);
      }
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms();
  }, [patientId, page, rowsPerPage]);

  const handleFormClick = (form: Form) => {
    setSelectedForm(form);
    setIsFormDetailsOpen(true);
  };

  const fetchFormDetail = async (formSessionId: string) => {
    setIsLoadingDetail(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/formDetail?form_session_id=${formSessionId}`,
        accessToken,
      });

      if (response.ok) {
        setFormDetail(response.value.form_detail);
      }
    } catch (error) {
      console.error("Error fetching form details:", error);
    } finally {
      setIsLoadingDetail(false);
    }
  };

  useEffect(() => {
    if (selectedForm?.form_session_id) {
      fetchFormDetail(selectedForm.form_session_id);
    } else {
      setFormDetail(null);
    }
  }, [selectedForm]);

  const handleFormDetailsClose = () => {
    setIsFormDetailsOpen(false);
    setSelectedForm(null);
    setFormDetail(null);
    window.history.replaceState(null, "", window.location.pathname);
  };

  const fetchAvailableForms = async (page: number, search?: string) => {
    try {
      const accessToken = await getAccessToken();
      const skip = (page - 1) * FORMS_PER_PAGE;
      const searchQuery = search ? `&search=${encodeURIComponent(search)}` : "";

      const response = await APIService.makeAPIGetRequest({
        requestString: `/forms/list?skip=${skip}&limit=${FORMS_PER_PAGE}${searchQuery}`,
        accessToken,
      });

      if (response.ok) {
        const data: FormListResponse = response.value;
        setAvailableForms(data.forms);
        setTotalFormPages(Math.ceil(data.pagination.total / FORMS_PER_PAGE));
      }
    } catch (error) {
      console.error("Error fetching available forms:", error);
    }
  };

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue: string) => {
        setFormListPage(1); // Reset to first page when searching
        fetchAvailableForms(1, searchValue);
      }, 500),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleSearchSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    debouncedSearch.cancel(); // Cancel any pending debounced search
    setFormListPage(1);
    fetchAvailableForms(1, searchTerm);
  };

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleAddForm = () => {
    if (!patient?.dob) {
      enqueueSnackbar(
        "Please fill out patient's date of birth before sending forms.",
        {
          variant: "error",
        }
      );
      return;
    }

    setIsFormSelectorOpen(true);
    setFormListPage(1);
    fetchAvailableForms(1);
  };

  const handleFormSelect = (formId: string) => {
    setSelectedFormIds((prev) =>
      prev.includes(formId)
        ? prev.filter((id) => id !== formId)
        : [...prev, formId]
    );
  };

  const handleCreateFormSession = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/assignForms",
        accessToken,
        body: {
          patient_id: patientId,
          form_ids: selectedFormIds,
        },
      });

      if (response.ok) {
        setIsFormSelectorOpen(false);
        setSelectedFormIds([]);
        fetchForms(); // Refresh the forms list
      }
    } catch (error) {
      console.error("Error creating form session:", error);
    }
  };

  const handleFormListPageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setFormListPage(page);
    fetchAvailableForms(page, searchTerm);
  };

  return (
    <>
      <ListBlock
        title="Forms"
        columns={columns}
        data={forms}
        loading={loading}
        actions={
          <Tooltip
            title={
              !patient?.dob
                ? "Patient date of birth is required to send forms"
                : ""
            }
            arrow
          >
            <span>
              <Button
                variant="outlined"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleAddForm}
                disabled={!patient?.dob}
              >
                Send Form
              </Button>
            </span>
          </Tooltip>
        }
        onRowClick={handleFormClick}
        page={page}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
        onPageChange={setPage}
        onRowsPerPageChange={setRowsPerPage}
      />

      <SlideSidePane
        open={isFormSelectorOpen}
        onClose={() => setIsFormSelectorOpen(false)}
        title="Select Forms"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
          }}
        >
          <form onSubmit={handleSearchSubmit}>
            <TextField
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search forms..."
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      edge="end"
                      aria-label="search forms"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />
          </form>

          <Box sx={{ flexGrow: 1, overflow: "auto" }}>
            {availableForms.map((form) => (
              <Card
                key={form.form_id}
                sx={{
                  cursor: "pointer",
                  bgcolor: selectedFormIds.includes(form.form_id)
                    ? "action.selected"
                    : "background.paper",
                  mb: 2,
                }}
                onClick={() => handleFormSelect(form.form_id)}
              >
                <CardContent>
                  <Typography variant="h6">{form.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {form.description}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {form.groups.length} section
                    {form.groups.length !== 1 ? "s" : ""}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>

          <Stack
            spacing={2}
            sx={{ pt: 2, borderTop: 1, borderColor: "divider" }}
          >
            <Pagination
              count={totalFormPages}
              page={formListPage}
              onChange={handleFormListPageChange}
              color="primary"
              sx={{ alignSelf: "center" }}
            />

            <Button
              variant="contained"
              disabled={selectedFormIds.length === 0}
              onClick={handleCreateFormSession}
              fullWidth
            >
              Assign Selected Forms ({selectedFormIds.length})
            </Button>
          </Stack>
        </Box>
      </SlideSidePane>

      <SlideSidePane
        open={isFormDetailsOpen}
        onClose={handleFormDetailsClose}
        title={selectedForm?.name || "Form Details"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {isLoadingDetail ? (
            <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : selectedForm && formDetail ? (
            <>
              {/* Scrollable content area */}
              <Box
                sx={{
                  p: 2,
                  flexGrow: 1,
                  overflow: "auto",
                  pb: 9, // Add padding to prevent content from going under button
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {formDetail.form_definition.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {formDetail.form_definition.description}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Status: {formDetail.status}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Started: {formatDate(formDetail.start_timestamp * 1000)}
                </Typography>
                {formDetail.end_timestamp && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Completed: {formatDate(formDetail.end_timestamp * 1000)}
                  </Typography>
                )}

                <Box sx={{ mt: 3 }}>
                  {formDetail.status === "completed" && (
                    <Box sx={{ mt: 2 }}>
                      <FormSubmissionView
                        formDefinition={formDetail.form_definition}
                        submissionData={formDetail.submission_data}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Sticky footer */}
              {formDetail.status !== "completed" && (
                <Box
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    p: 2,
                    bgcolor: "background.paper",
                    borderTop: 1,
                    borderColor: "divider",
                    mt: "auto", // Push to bottom of flex container
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      const patientFormUrl = `${
                        import.meta.env.VITE_FORMS_URL
                      }/f/${formDetail.form_session_id}`;
                      navigator.clipboard
                        .writeText(patientFormUrl)
                        .then(() => {
                          enqueueSnackbar("Link copied to clipboard!", {
                            variant: "success",
                          });
                        })
                        .catch((error) => {
                          console.error("Failed to copy:", error);
                          enqueueSnackbar("Failed to copy link", {
                            variant: "error",
                          });
                        });
                    }}
                  >
                    Copy Patient Link
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ p: 2 }}>
              <Typography color="text.secondary">
                No form details available
              </Typography>
            </Box>
          )}
        </Box>
      </SlideSidePane>
    </>
  );
};

export default FormsListBlock;

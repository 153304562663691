/**
 * @file src/web-app/src/Views/Shared/ClaimMDHelperFunctionalities.tsx
 * @description This file contains the ClearinghouseLinked React component: It is used to link the user's Claim.MD account with JotPsych.
 *
 * Author: Soham Dave
 * Date: 2024-10-08
 */

import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import { ConditionalTooltip } from "../../components/ConditionalTooltip";
import {
  GreenBubble,
  YellowBubble,
} from "../../components/Icons/ColoredBubbles";
import { RoundedButton } from "../../styles/CustomButtons";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { trackEvent } from "../../utils/analytics_utils";

interface ClearinghouseLinkedProps {
  isClearinghouseLinked: boolean;
  setIsClearinghouseLinked: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onLinkClick?: () => void;
}

export const ClearinghouseLinked: React.FC<ClearinghouseLinkedProps> = ({
  isClearinghouseLinked,
  setIsClearinghouseLinked,
  showModal,
  setShowModal,
  onLinkClick,
}) => {
  const [accountKey, setAccountKey] = useState<string>("");
  const [isLinking, setIsLinking] = useState<boolean>(false);
  const [linkingError, setLinkingError] = useState<string | null>(null);
  const [linkingSuccess, setLinkingSuccess] = useState<boolean>(false);
  const { getAccessToken, userState } = useUser();
  const win: Window = window;

  const handleLinkAccount = useCallback(async () => {
    setIsLinking(true);
    setLinkingError(null);
    setLinkingSuccess(false);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/updateUserClearinghouse",
        accessToken,
        body: { clearinghouseAccountKey: accountKey.trim() },
      });

      if (response.ok) {
        setIsClearinghouseLinked(true);
        setShowModal(false);
        setLinkingSuccess(true);
        trackEvent({
          event: "claim_md_account_linked",
          event_category: "Clearinghouse",
          event_label: "Successfully Linked Claim.MD Account",
        });
      } else {
        throw new Error(response.error.message || "Failed to link account");
      }
    } catch (error) {
      console.error("Error linking account:", error);
      setLinkingError(
        error instanceof Error ? error.message : "An unexpected error occurred"
      );
    } finally {
      setIsLinking(false);
    }
  }, [accountKey, getAccessToken, setIsClearinghouseLinked, userState]);

  const handleSignUpClick = () => {
    trackEvent({
      event: "claim_md_signup_click",
      event_category: "Clearinghouse",
      event_label: "Sign Up for Claim.MD",
    });
    // Existing sign-up logic here
    window.open("https://www.claim.md/get-started", "_blank");
  };

  const handleLinkClick = () => {
    if (onLinkClick) {
      onLinkClick();
    }
    setShowModal(true);
  };

  return (
    <>
      <ConditionalTooltip
        title={
          !isClearinghouseLinked
            ? `Link Claim.MD with JotPsych to submit this billing`
            : ""
        }
        arrow
        condition={!isClearinghouseLinked}
        placement={"left"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 1,
            cursor: "pointer",
          }}
          onClick={handleLinkClick}
        >
          {!isClearinghouseLinked ? <YellowBubble /> : <GreenBubble />}
          <Typography>Clearinghouse Linked</Typography>
        </Box>
      </ConditionalTooltip>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "24px",
            width: { xs: "90%", sm: "450px" },
            maxWidth: "450px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h4" gutterBottom fontWeight="bold">
            Link Claim.MD Account
          </Typography>

          <Box
            sx={{
              bgcolor: "#f5f5f5",
              borderRadius: "8px",
              p: 2,
              mb: 3,
              position: "relative",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box
                sx={{
                  bgcolor: "#6200ee",
                  borderRadius: "50%",
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: 1,
                }}
              >
                <Typography variant="body2" color="white">
                  $
                </Typography>
              </Box>
              <Typography variant="subtitle1" fontWeight="bold">
                New to Claim.MD?
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mb: 2 }}>
              If you don't already have an account with Claim.MD,{" "}
              <b>sign up to connect with JotPsych.</b>
            </Typography>
            <RoundedButton
              variant="contained"
              sx={{
                position: "absolute",
                bottom: -20,
                right: 16,
                bgcolor: "black",
                color: "white",
                textTransform: "none",
                "&:hover": { bgcolor: "black" },
              }}
              onClick={handleSignUpClick}
            >
              Sign up
            </RoundedButton>
          </Box>

          <Typography variant="body2" sx={{ mb: 3 }}>
            Claim.MD is a web-based medical claims clearinghouse that manages
            every aspect of the revenue cycle.
          </Typography>

          <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
            Account Key
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="e.g. siAQCBNWiYAbcH8AecMTxo@pR2.bZ"
            sx={{ mb: 1 }}
            value={accountKey}
            onChange={(e) => setAccountKey(e.target.value)}
          />

          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            You will be provided the Account Key within the Claim.MD web portal.
            (Settings -&gt; Account Settings -&gt; API Key) Contact Claim.MD
            support if no key generation options are available.
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <RoundedButton
              variant="contained"
              sx={{
                bgcolor: "black",
                color: "white",
                textTransform: "none",
                "&:hover": { bgcolor: "black" },
              }}
              onClick={handleLinkAccount}
              disabled={isLinking || !accountKey.trim()}
            >
              {isLinking ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Link"
              )}
            </RoundedButton>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={!!linkingError}
        autoHideDuration={6000}
        onClose={() => setLinkingError(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          zIndex: 20001,
          "& .MuiSnackbarContent-root": {
            minWidth: "250px",
            maxWidth: "80%",
          },
        }}
      >
        <Alert
          onClose={() => setLinkingError(null)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {linkingError}
        </Alert>
      </Snackbar>

      <Snackbar
        open={linkingSuccess}
        autoHideDuration={6000}
        onClose={() => setLinkingSuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          zIndex: 9999,
          "& .MuiSnackbarContent-root": {
            minWidth: "250px",
            maxWidth: "80%",
          },
        }}
      >
        <Alert
          onClose={() => setLinkingSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Account successfully linked!
        </Alert>
      </Snackbar>
    </>
  );
};

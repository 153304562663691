import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  useMediaQuery,
  Theme,
  Skeleton,
  Card,
  CardContent,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  ArrowBack,
  Edit as EditIcon,
  KeyboardArrowLeftOutlined,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Settings,
  Widgets,
} from "@mui/icons-material";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { useNavigate, useParams } from "react-router-dom";
import PatientInformationBlock from "./PatientInformationBlock";
import TextBlock from "./Blocks/TextBlock";
import StructuredDataBlock from "./StructuredDataBlock";
import PatientEncounters from "./PatientEncountersBlock";
import MedicationsDataBlock, {
  Medication,
  MedicationChange,
} from "./MedicationsDataBlock";
import DiagnosesDataBlock, { Diagnosis } from "./DiagnosesDataBlock";
import LayoutWrapper from "../layout/UILayout";
import { removeScrollBar } from "../../styles/globalStyles";
import { DismissableCard } from "../../Views/Shared/DismissableCard";
import { RoundedButton } from "../../styles/CustomButtons";
import { DeleteModal } from "../DeleteModal";
import { ConditionalTooltip } from "../ConditionalTooltip";
import FormsListBlock from "./Blocks/FormsListBlock";
import { useSnackbar } from "notistack";
import TableBlock from "./Blocks/TableBlock";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import { PatientModule } from "../../types/types";
import TestBlock from "./Blocks/TestBlock";
import { phq9Fixture } from "./Blocks/fixtures/testBlockFixtures";
import PatientOverviewBlock from "./Blocks/PatientOverviewBlock";
import { FeatureFeedback } from "../FeatureFeedback/FeatureFeedback";

const PatientDetailView: React.FC = () => {
  const { patientId } = useParams();
  const { getAccessToken } = useUser();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { userState } = useUser();

  const [patient, setPatient] = useState<Patient | null>(null);
  const [originalPatient, setOriginalPatient] = useState<Patient | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);

  const [patientModulesData, setPatientModulesData] = useState<any>(null);
  const [loadingModules, setLoadingModules] = useState(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showTooltips, setShowTooltips] = useState(true);
  const [showPatientSectionsTooltip, setShowPatientSectionsTooltip] = useState(
    localStorage.getItem("editPatientSectionsClicked") !== "true"
  );

  const [shouldNavigate, setShouldNavigate] = useState(false);

  const [showModulesPane, setShowModulesPane] = useState(false);
  const [availableModules, setAvailableModules] = useState<PatientModule[]>([]);
  const [userPatientModules, setUserPatientModules] = useState<PatientModule[]>(
    []
  );

  useEffect(() => {
    fetchPatient();
    fetchPatientData();
  }, [patientId]);

  useEffect(() => {
    if (shouldNavigate) {
      const timer = setTimeout(() => {
        navigate("/patients");
      }, 2000); // Wait for 2 seconds before navigating

      return () => clearTimeout(timer);
    }
  }, [shouldNavigate, navigate]);

  const fetchPatient = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/get?patient_id=${patientId}&origin=PatientDetailView`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setPatient(data.patient);
      } else {
        throw new Error("Failed to fetch patient");
      }
    } catch (error) {
      console.error("Error fetching patient:", error);
      enqueueSnackbar("Failed to load patient data", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const fetchPatientData = async () => {
    setLoadingModules(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/getPatientData?patient_id=${patientId}`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        console.log("patient data", data);
        setPatientModulesData(data.patient_data.patient_module_data);
      } else {
        throw new Error("Failed to fetch patient modules data");
      }
    } catch (error) {
      console.error("Error fetching patient modules data:", error);
      enqueueSnackbar("Failed to load patient modules data", {
        variant: "error",
      });
    } finally {
      setLoadingModules(false);
    }
  };

  const handleSave = async () => {
    if (!patient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/update",
        accessToken,
        body: {
          patient_id: patientId,
          updated_fields: patient,
        },
      });

      if (response.ok) {
        enqueueSnackbar("Patient updated successfully", { variant: "success" });
        setOriginalPatient(patient);
        setHasChanges(false);
      } else {
        throw new Error("Failed to update patient");
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      enqueueSnackbar("Failed to update patient", { variant: "error" });
    }
  };

  const handleDeletePatient = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: `/patient/delete`,
        accessToken,
        body: {
          patient_id: patientId,
        },
      });

      if (response.ok) {
        enqueueSnackbar("Patient deleted successfully", { variant: "success" });
        setShouldNavigate(true);
      } else {
        throw new Error("Failed to delete patient");
      }
    } catch (error) {
      console.error("Error deleting patient:", error);
      enqueueSnackbar("Failed to delete patient", { variant: "error" });
    } finally {
      setShowDeleteModal(false);
    }
  };

  const isEqual = useCallback((obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }, []);

  const fetchAvailableModules = async () => {
    setLoadingModules(true);
    try {
      const accessToken = await getAccessToken();
      const [availableResponse, userModulesResponse] = await Promise.all([
        APIService.makeAPIGetRequest({
          requestString: "/patient_module/getPublicPatientModules",
          accessToken,
        }),
        APIService.makeAPIGetRequest({
          requestString: `/patient_module/getUserPatientModules`,
          accessToken,
        }),
      ]);

      if (availableResponse.ok && userModulesResponse.ok) {
        const availableData = await availableResponse.value;
        const userModulesData = await userModulesResponse.value;

        // Find the full module data for enabled user modules
        const userModules = availableData.filter(
          (module: PatientModule) =>
            userModulesData[module.patient_module_id] === true
        );

        setAvailableModules(availableData);
        setUserPatientModules(userModules);
      } else {
        throw new Error("Failed to fetch modules");
      }
    } catch (error) {
      console.error("Error fetching modules:", error);
      enqueueSnackbar("Failed to load modules", { variant: "error" });
    } finally {
      setLoadingModules(false);
    }
  };

  const handleAssignModule = async (moduleId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient_module/assignUserPatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleId,
        },
      });

      if (response.ok) {
        enqueueSnackbar("Module added successfully", { variant: "success" });
        setShowModulesPane(false);
        fetchPatientData(); // Refresh patient data
      } else {
        throw new Error("Failed to assign module");
      }
    } catch (error) {
      console.error("Error assigning module:", error);
      enqueueSnackbar("Failed to add module", { variant: "error" });
    }
  };

  const handleRemoveModule = async (moduleId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient_module/removeUserPatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleId,
        },
      });

      if (response.ok) {
        enqueueSnackbar("Module removed successfully", { variant: "success" });
        setShowModulesPane(false);
        fetchPatientData(); // Refresh patient data
      } else {
        throw new Error("Failed to remove module");
      }
    } catch (error) {
      console.error("Error removing module:", error);
      enqueueSnackbar("Failed to remove module", { variant: "error" });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!patient || !patientId) {
    return <Typography>No patient data available.</Typography>;
  }

  return (
    <>
      <DeleteModal
        isOpen={showDeleteModal}
        continueText="Delete"
        cancelText="Cancel"
        onCancel={() => setShowDeleteModal(false)}
        onContinue={handleDeletePatient}
      >
        Are you sure you want to delete this patient and all associated data?
        This action cannot be undone.
      </DeleteModal>
      <LayoutWrapper>
        <LayoutWrapper.TopBar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: 2,
              height: "100%",
              mx: 2,
              overflowX: "auto",
            }}
          >
            <Typography
              variant="body2"
              onClick={() => navigate(-1)}
              sx={{
                color: "primary.main",
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <KeyboardArrowLeftOutlined />
              Back
            </Typography>
            <Box>
              <ConditionalTooltip
                title={
                  localStorage.getItem("editPatientSectionsClicked") !== "true"
                    ? "Click here to customize your view"
                    : "Change sections"
                }
                condition={showTooltips}
                placement="bottom"
                arrow
                open={
                  localStorage.getItem("editPatientSectionsClicked") !== "true"
                    ? showPatientSectionsTooltip
                    : undefined
                }
              >
                <IconButton
                  onClick={() => {
                    setShowModulesPane(true);
                    fetchAvailableModules();
                    setShowPatientSectionsTooltip(false);
                    localStorage.setItem("editPatientSectionsClicked", "true");
                  }}
                >
                  <Widgets />
                </IconButton>
              </ConditionalTooltip>
              <ConditionalTooltip
                title="Delete patient"
                condition={showTooltips}
                placement="bottom"
                arrow
              >
                <IconButton onClick={() => setShowDeleteModal(true)}>
                  <DeleteIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </ConditionalTooltip>
            </Box>
          </Box>
        </LayoutWrapper.TopBar>
        <LayoutWrapper.MainContent>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 2,
              }}
            >
              {hasChanges && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  sx={{ mt: 2 }}
                >
                  Save Changes
                </Button>
              )}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box sx={{ position: "sticky", top: 16 }}>
                  <PatientOverviewBlock
                    patient={patient}
                    onPatientUpdate={(updatedPatient) => {
                      setPatient(updatedPatient);
                    }}
                  />
                  {patientId && <PatientEncounters patientId={patientId} />}
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                {Object.entries(patientModulesData || {}).map(
                  ([moduleId, moduleData]: [string, any]) => {
                    const title =
                      moduleData.name[0].toUpperCase() +
                      moduleData.name.substring(1);

                    let moduleBlock = null;
                    switch (moduleData?.type) {
                      case "table":
                        moduleBlock = (
                          <TableBlock
                            key={moduleId}
                            patientId={patientId}
                            moduleData={moduleData}
                            title={title}
                            onDataUpdate={(updatedData: any, action: any) => {
                              // TODO: Implement the update handler
                              console.log(
                                "Update data for module:",
                                moduleId,
                                updatedData,
                                action
                              );
                            }}
                          />
                        );
                        break;
                      case "test":
                        moduleBlock = (
                          <TestBlock
                            key={moduleId}
                            patientId={patientId}
                            moduleData={moduleData}
                            title={title}
                            patient={patient}
                          />
                        );
                        break;
                      case "text":
                        moduleBlock = (
                          <TextBlock
                            key={moduleId}
                            patientId={patientId}
                            moduleData={moduleData}
                            title={title}
                          />
                        );
                        break;
                      default:
                        return null;
                    }

                    return (
                      <React.Fragment key={moduleId}>
                        {moduleBlock}
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <FeatureFeedback
                            featureId={`${moduleId}_feedback`}
                            mountsUntilShow={5}
                            percentToShow={10}
                            prompt="Are you finding this feature useful?"
                            context={{
                              patient_module_data: moduleData,
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    );
                  }
                )}
                {userState?.featureFlags?.forms && patientId && (
                  <FormsListBlock patientId={patientId} patient={patient} />
                )}
              </Grid>
            </Grid>
          </Box>
        </LayoutWrapper.MainContent>
        <LayoutWrapper.RightSidebar>
          <DismissableCard
            headerTitle="Who can see this information?"
            bodyText={[
              "JotPsych securely encrypts all identifiable patient information (name, DOB, contact info, etc.). This information is never shared with any AI models or third parties and can only be seen by you and other members of your organization after you've entered an authenticated session.",
            ]}
            learnMoreLink="https://www.jotpsych.com/security-and-privacy"
            tooltipMessage="Learn more about our security practices."
          />
          {/* {!patientModulesData.medications_block &&
            !patientModulesData.diagnoses_block && (
              <DismissableCard
                headerTitle="Next Steps?"
                bodyText={[
                  "Record an encounter with this patient (or assign a previous note to them from the Note page).",
                  "After you click 'complete note', come back to this screen to review and approve changes to the patient's medications or diagnoses.",
                ]}
              />
            )}
          {(patientModulesData.medications_block ||
            patientModulesData.diagnoses_block) && (
            <DismissableCard
              headerTitle="Approve changes"
              bodyText={[
                "JotPsych automatically updates the patient's information after you complete a note. You can review and approve these changes on this page.",
                "Over time, this information will become more complete and give you a quick overview of your patients and their history.",
                "You can also manually update the patient's information, and it'll improve the accuracy of your future notes.",
              ]}
            />
          )} */}
        </LayoutWrapper.RightSidebar>
      </LayoutWrapper>
      <SlideSidePane
        open={showModulesPane}
        onClose={() => setShowModulesPane(false)}
        title="Patient Sections"
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Assigned Sections
            </Typography>
            {loadingModules ? (
              <CircularProgress />
            ) : userPatientModules.length === 0 ? (
              <Typography color="text.secondary">
                No sections assigned yet
              </Typography>
            ) : (
              userPatientModules.map((module) => (
                <Card key={module.patient_module_id} sx={{ p: 2, mb: 1 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        {module.public_name || module.patient_module_name}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() =>
                        handleRemoveModule(module.patient_module_id)
                      }
                    >
                      Remove
                    </Button>
                  </Stack>
                </Card>
              ))
            )}
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Available Sections
            </Typography>
            {loadingModules ? (
              <CircularProgress />
            ) : (
              availableModules
                .filter(
                  (module) =>
                    !userPatientModules.some(
                      (assigned) =>
                        assigned.patient_module_id === module.patient_module_id
                    )
                )
                .map((module) => (
                  <Card key={module.patient_module_id} sx={{ p: 2, mb: 1 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          {module.public_name || module.patient_module_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {module.public_description}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          handleAssignModule(module.patient_module_id)
                        }
                      >
                        Add
                      </Button>
                    </Stack>
                  </Card>
                ))
            )}
          </Box>
        </Box>
      </SlideSidePane>
    </>
  );
};

export default PatientDetailView;

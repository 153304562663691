// src/components/Modal.tsx
import React, { MouseEvent, ReactNode, useState } from "react";
import { Box, Typography, useTheme, IconButton, Stack } from "@mui/material";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import CustomButton, { RoundedButton } from "../../styles/CustomButtons";
import { useThemeContext } from "../../context/theme/context";

interface Feature {
  title: string;
  date: string;
  content: ReactNode;
}

interface FeatureDictionary {
  [version: string]: Feature;
}

export interface NewFeatureModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NewFeatureModal: React.FC<NewFeatureModalProps> = ({
  isOpen,
  onClose,
}) => {
  const theme = useTheme();
  const { brandLanguage } = useThemeContext();
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const versions = Object.keys(featureDictionary).sort().reverse();

  if (!currentVersion) {
    setCurrentVersion(versions[0]);
  }

  const currentFeature = featureDictionary[currentVersion || ""];

  const handlePrevVersion = () => {
    const currentIndex = versions.indexOf(currentVersion || "");
    if (currentIndex < versions.length - 1) {
      setCurrentVersion(versions[currentIndex + 1]);
    }
  };

  const handleNextVersion = () => {
    const currentIndex = versions.indexOf(currentVersion || "");
    if (currentIndex > 0) {
      setCurrentVersion(versions[currentIndex - 1]);
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      onClick={handleOverlayClick}
    >
      <Box
        sx={{
          bgcolor: "backgroundColors.primary",
          borderRadius: "12px",
          p: 4,
          width: { xs: "90%", md: "50%" },
          fontWeight: "400",
          color: "textColors.darkText",
          maxWidth: "90vw",
          height: "100%",
          maxHeight: "70vh",
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "textColors.darkText",
          }}
        >
          <Close />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton
            onClick={handlePrevVersion}
            disabled={
              versions.indexOf(currentVersion || "") === versions.length - 1
            }
          >
            <ChevronLeft />
          </IconButton>
          {currentFeature && (
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>{currentFeature.date}</Box>
              <Box>{currentFeature.title}</Box>
            </Typography>
          )}

          <IconButton
            onClick={handleNextVersion}
            disabled={versions.indexOf(currentVersion || "") === 0}
          >
            <ChevronRight />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: "85%",
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4,
          }}
        >
          {currentFeature && currentFeature.content}
        </Box>
      </Box>
    </Box>
  );
};

const featureDictionary: FeatureDictionary = {
  "1.0.1": {
    title: "Section Customization",
    date: "April 15, 2024",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>We have another exciting new feature for you!</Typography>
        <Typography>
          Now, you can further refine and train your scribe by giving it
          specific customization instructions for individual sections. Your
          scribe will remember your preferences and write future note sections
          accordingly.
        </Typography>
        <Typography>
          To get started, click on the "..." next to a note section and launch
          the customization wizard.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <img
            src="/new-feature-images/customize_button.gif"
            alt="Customization"
            style={{ width: "80%" }}
          />
        </Box>
        <Typography>
          We're excited to see how you customize your note sections!
        </Typography>
        <Typography>
          You can always see which sections you have customized, and revert
          those changes via the template builder.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <img
            src="/new-feature-images/customization_revert.png"
            alt="Customization"
            style={{ width: "80%" }}
          />
        </Box>
        <Typography>
          So go check it out, and as always, let us know what you think!
        </Typography>
      </Stack>
    ),
  },
  "1.0.2": {
    title: "Voice Detection and Speaker Labeling",
    date: "May 29, 2024",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>
          We've been hard at work rolling out some snazzy new voice detection AI
          models, and we're pleased to announce our newest feature!
        </Typography>
        <Typography>
          If you tab over to the transcript on one of your encounter notes, you
          might notice some new text on the lefthand side:
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <img
            src="/new-feature-images/speaker_labels.png"
            alt="Customization"
            style={{ width: "80%" }}
          />
        </Box>
        <Typography>
          If you don't see labels yet, just be patient! Our model should learn
          your voice after about 3-5 encounters.
        </Typography>
        <Typography>
          (NB: If you'd prefer your label to say 'client' or 'patient', you can
          change this setting on your user page by clicking your user circle in
          the top right of the app.)
        </Typography>
        <Typography>
          So go try it out, enjoy improved accuracy in your notes, and let us
          know what you think!
        </Typography>
      </Stack>
    ),
  },
  "1.0.3": {
    title: "Unlimited Additional Context",
    date: "June 7, 2024",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>Ready to add even more context to your notes?</Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/context_paste.gif"
              alt="Customization"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          We've done away with the 500 character limit on our 'Additional Info'
          box in the Chrome Extension. You can now paste documents of any size,
          and our system will take this information into consideration when
          generating your note.
        </Typography>
        <Typography>
          Take any document (Intake forms, referral letters, lab reports, etc.)
          and paste them in when you are submitting your note!
        </Typography>
        <Typography>
          Check it out and please let us know what you think via the chat button
          in the bottom right corner of your screen!
        </Typography>
      </Stack>
    ),
  },
  "1.0.4": {
    title: "Web Recorder",
    date: "July 3, 2024",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>
          Now you can record in your web browser on your computer, iOS, and
          Android. No need to install any apps or extensions!
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/webrecorder_web.gif"
              alt="Web Recorder Web"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          The same recording features that you're used to using in our Chrome
          Extension and iOS application are now available everywhere.
        </Typography>
        <Typography>
          Just click or touch the new "Record" menu option and get started!
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/webrecorder_mobile.gif"
              alt="Web Recorder Mobile"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
      </Stack>
    ),
  },
  "1.0.6": {
    title: "AI Instructions Box",
    date: "August 13, 2024",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>
          Have you tried editing or customizing your note sections with AI yet?
          Just click the edit button and then enter your instructions into the
          AI Instructions box!
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/ai_instruction_box.gif"
              alt="AI Instructions Box"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          We've combined AI Editing and Section Customization into one simple
          interface. Just enter your instructions into the box and our system
          will intelligently decide if you want to edit that particular note
          section or customize the behavior of that section for future notes.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/ai_option_selection.png"
              alt="Dropdown Options"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          If you know exactly what you want to do, you can select "Edit" or
          "Teach" from the dropdown menu as well.
        </Typography>
        <Typography>
          Haven't tried customizing the behavior of your note sections before?
          Try entering instructions with "always" or "never", and we'll generate
          that note section according to your exact specifications from now on!
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/translate_spanish.gif"
              alt="Translate to Spanish"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
      </Stack>
    ),
  },
  "1.0.7": {
    title: "JotPsych has a new look!",
    date: "September 20, 2024",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>
          We've been working hard on a complete redesign of the JotPsych web
          application, and we're excited to share it with you!
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/newlook.png"
              alt="New Look Dashboard"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          You'll find all the same features you're used to, but some buttons
          have moved around. The highlights are below.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/note_toolbar.png"
              alt="Note Toolbar"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          Most of buttons in the note view are now in a toolbar at the top of
          the screen.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/complete_note.png"
              alt="Complete Note"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          Archived notes are now called "completed notes" and can be marked as
          completed from the bottom toolbar in the note view. Don't worry, all
          of your previously archived notes are now "completed" and can found
          via the same filters on the notes list page.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/notes_list_completed.png"
              alt="Notes List"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          On desktop, you'll now find a right sidebar with some helpful tooltips
          and additional tools. You can always click the 'x' on the tooltips and
          they won't show up again. To create more room on your screen, you can
          temporarily dock the sidebar.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/sidebar.gif"
              alt="Sidebar Docked"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          We hope you like the new look! Please let us know what you think via
          the chat button in the bottom right corner of your screen!
        </Typography>
      </Stack>
    ),
  },
  "1.0.8": {
    title: "Patient Context",
    date: "October 30, 2024",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>
          JotPsych now remembers information about your patients and clients,
          and will use it to generate richer, more accurate notes.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/patient_picker.png"
              alt="Patient Picker"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          Assign a patient to an encounter by selecting them in the recorder.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/patient_medications_diagnoses.png"
              alt="Patient Medications and Diagnoses"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          JotPsych automatically learns about your patient's medications,
          diagnoses, and other information from your encounters.
        </Typography>
        <Typography>
          As always, JotPsych takes patient privacy and security very seriously.
          We securely encrypt all identifiable patient information (name, DOB,
          contact info, etc.). This information is never shared with any AI
          models or third parties and can only be seen by you and other members
          of your organization after you've entered an authenticated session.
        </Typography>
      </Stack>
    ),
  },
  "1.1.0": {
    title: "New Patient Overview",
    date: "January 18, 2025",
    content: (
      <Stack gap={4} sx={{ width: "80%" }}>
        <Typography>
          We've redesigned a new patient overview page to help you keep track of
          your patients and clients.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/patient_overview.png"
              alt="Patient Overview"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          From the recorder, you can now see information about the last
          encounter with a patient/client at a glance.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/last_encounter.png"
              alt="Last Encounter"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          JotPsych continues to automatically learn about your patients from
          your encounters, and will use this information to generate richer,
          more accurate notes.
        </Typography>
        <Typography>
          JotPsych will suggest changes to your patient record based on your
          encounters. You can review, accept, or modify these changes from the
          patient overview.
        </Typography>
        <Box display="flex" justifyContent="center" width="100%">
          <Box display="flex" justifyContent="center" width="100%">
            <img
              src="/new-feature-images/accept_changes.png"
              alt="Accept Changes"
              style={{ width: "80%" }}
            />
          </Box>
        </Box>
        <Typography>
          What else would you like to see in the patient overview? Let us know
          what you think via the chat button in the bottom right corner of your
          screen!
        </Typography>
      </Stack>
    ),
  },
  // Add more versions...
};

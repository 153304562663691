import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { useRecordingContext } from "../context/RecordingContext";
import { useUser } from "../context/user";
import { useUIState } from "../context/uiState";
import AudioVisualizer from "../components/AudioVisualizer";
import { Patient, UserTemplateItem } from "../types/types";
import { useNavigate } from "react-router-dom";
import { DeleteModal } from "../components/DeleteModal";
import SentryService from "../services/SentryService";
import PatientPicker from "../components/Patient/PatientPicker";
import { Person, Visibility } from "@mui/icons-material";
import LayoutWrapper from "../components/layout/UILayout";
import { DismissableCard } from "./Shared/DismissableCard";
import { RoundedButton } from "../styles/CustomButtons";
import { trackEvent } from "../utils/analytics_utils";
import { Checklist } from "./Checklist";
import { differenceInYears } from "date-fns";
import { PastVisitSummary } from "../components/PastVisitSummary";
import CollapsibleContentCard from "../components/CollapsibleContentCard/CollapsibleContentCard";

const SPINNER_TIMEOUT = 45000; // 45 seconds

const VisitSummarySection = memo(
  ({ patient }: { patient: Patient | undefined }) => {
    return (
      <Box
        sx={{
          width: { xs: "95%", md: "80%" },
          maxWidth: 500,
          padding: 2,
          overflowY: "auto",
          maxHeight: "100vh",
          mt: 5,
          gap: 1,
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
        }}
      >
        <PastVisitSummary patientId={patient?.patient_id} />
      </Box>
    );
  }
);

export const RecorderView: React.FC = () => {
  const {
    recordingStatus,
    noteInformation,
    setNoteInformation,
    setUseSharedMedia,
    useSharedMedia,
    microphonePermissionGranted,
    checkMicrophonePermission,
    selectedMicrophone,
    setNoAudioStatus,
    noAudioStatus,
    initiateRecording,
    initiateEndRecording,
    sendRecording,
    pauseRecording,
    resumeRecording,
    setSelectedMicrophone,
    getAvailableMicrophones,
    availableMicrophones,
    deleteRecordingHelper,
    elapsedTime,
    cleanUpSession,
    releaseMicrophone,
    initiateReupload,
    lowDataMode,
    sendMessageToExtension,
    currentTranscriptionSessionId,
  } = useRecordingContext();

  const { userState, templatesList, updateOnboardingStep } = useUser();
  const { state, showAlertBanner } = useUIState();
  const { platform, browser, loadedInChromeExtension } = state;
  const navigate = useNavigate();

  const [noteTitleError, setNoteTitleError] = useState(false);
  const [ageError, setAgeError] = useState("");
  const [spinnerError, setSpinnerError] = useState(false);
  const [timerDisplay, setTimerDisplay] = useState("0:00");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isRecording = useRef<boolean>(false);

  const [tutorial, setTutorial] = useState<boolean>(false);
  const [tutorialHeaderText, setTutorialHeaderText] = useState<string>(
    "Learn how to record an encounter"
  );
  const [tutorialDescription, setTutorialDescription] = useState<string[]>([
    "No need to install any extra software on your computer or phone. You can record with JotPsych on desktop and mobile by using this recording page.",
  ]);

  useEffect(() => {
    localStorage.setItem("elapsedTime", elapsedTime.toString());
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    const formattedTime = `${minutes}:${String(seconds).padStart(2, "0")}`;
    setTimerDisplay(formattedTime);
    sendMessageToExtension({ type: "recordingTime", data: formattedTime });
  }, [elapsedTime]);

  useEffect(() => {
    isRecording.current = recordingStatus.isRecording;
  }, [recordingStatus.isRecording]);

  useEffect(() => {
    if (microphonePermissionGranted === undefined) {
      getAvailableMicrophones();
    }

    // This will only run when the component unmounts
    return () => {
      if (!isRecording) {
        releaseMicrophone();
      }
    };
  }, []);

  useEffect(() => {
    let spinnerTimeout: NodeJS.Timeout;
    const baseTimeout = SPINNER_TIMEOUT;
    let timeoutDuration = baseTimeout;

    if (recordingStatus.isReuploading) {
      timeoutDuration = baseTimeout * 3; // 3x timeout for reuploading
    }

    if (
      recordingStatus.isReuploading ||
      recordingStatus.isStarting ||
      recordingStatus.isStopping
    ) {
      spinnerTimeout = setTimeout(() => {
        setSpinnerError(true);
        showAlertBanner(
          "An error occurred. Please refresh the page and try again.",
          "error"
        );
        SentryService.logEvent("Spinner timeout.", {
          level: "error",
          extra: { recordingStatus, timeoutDuration },
        });
      }, timeoutDuration);
    }

    return () => {
      if (spinnerTimeout) {
        clearTimeout(spinnerTimeout);
      }
    };
  }, [
    recordingStatus.isReuploading,
    recordingStatus.isStarting,
    recordingStatus.isStopping,
  ]);

  const handleNoteTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newNoteTitle = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      noteTitle: newNoteTitle,
    }));
    setNoteTitleError(newNoteTitle.trim() === "");
    if (tutorial) {
      setTutorialHeaderText("Select a template");
      setTutorialDescription([
        "Now let's pick a template. Templates are a fundamental component of JotPsych, as they allow you to choose which note sections get generated from an encounter. For now, let's just pick one that sounds interesting and we can learn more about templates in the template builder.",
      ]);
    }
  };

  const handlePatientChange = (patient?: Patient) => {
    setNoteInformation((prevInfo) => {
      // Set base patient info
      const updates = {
        ...prevInfo,
        patient: patient,
      };

      if (patient) {
        // Set pronouns based on gender
        if (patient.gender) {
          switch (patient.gender.toLowerCase()) {
            case "male":
              updates.pronouns = "he/him";
              break;
            case "female":
              updates.pronouns = "she/her";
              break;
            case "other":
              updates.pronouns = "they/them";
              break;
          }
        }

        // Calculate age from DOB
        if (patient.dob) {
          updates.age = differenceInYears(new Date(), new Date(patient.dob));
        }
      }

      return updates;
    });
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    const newTemplateId = event.target.value as string;
    setNoteInformation((prev) => ({ ...prev, templateId: newTemplateId }));
  };

  const handleTemplateOpen = () => {
    setTutorialHeaderText("Create a patient (optional)");
    setTutorialDescription([
      "If you select a patient/client to associate with this encounter, JotPsych will remember details about them for future encounters, enhancing the quality of your note and saving you more administrative time.",
    ]);
  };

  const handlePatientOpen = () => {
    if (tutorial) {
      setTutorialHeaderText("Add additional info (optional)");
      setTutorialDescription([
        "You can make your note more detailed by adding additional information about the encounter.",
        "Age, pronouns, and additional info are used to generate a more personalized note. You can add sparse shorthand notes, or paste in entire documents like intake forms. JotPsych will automatically incorporate this info for you!",
      ]);
    }
  };

  const handleInteractAdditionalInfo = () => {
    if (tutorial) {
      setTutorialHeaderText("Microphone");
      const description = [
        "Let's make sure your audio is set up correctly. You can select a microphone if you want, but in most cases the default is the best option.",
      ];
      if (browser === "chrome") {
        description.push(
          "If you want to record a telehealth visit and are wearing headphones, check the headphones box. This will prompt your browser to share the audio from another tab with JotPsych. Remember to check the 'Also share tab audio' box!"
        );
      }
      setTutorialDescription(description);
    }
  };

  const handleInteractAudio = () => {
    if (tutorial) {
      setTutorialHeaderText("Let's record already!");
      setTutorialDescription([
        "Press the record button and start talking. You can tell everything is working if you see sound waves on the screen.",
        "JotPsych works best with recordings of at least 3 minutes. When you're done, press the stop button.",
      ]);
    }
  };

  const handlePronounsChange = (event: SelectChangeEvent) => {
    const newPronouns = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      pronouns: newPronouns,
    }));
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAge = Number(event.target.value);
    if (newAge >= 0 && newAge <= 120) {
      setNoteInformation((prevInfo) => ({
        ...prevInfo,
        age: newAge,
      }));
      setAgeError("");
    } else {
      setAgeError("Age must be between 0 and 120.");
    }
  };

  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAdditionalInfo = event.target.value;
    setNoteInformation((prevInfo) => ({
      ...prevInfo,
      additionalInfo: newAdditionalInfo,
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseSharedMedia(event.target.checked);
  };

  const handleCheckFirstNote = async () => {
    if (userState?.onboardingSteps?.generated_first_note !== true) {
      updateOnboardingStep("generated_first_note", true);
      trackEvent({
        event: "generatedFirstNote",
        platform: platform,
      });
    }
  };

  const startRecording = async () => {
    const startRecordingStatus = await initiateRecording();
    if (startRecordingStatus) {
    } else {
      console.error("Failed to start recording.");
    }
  };

  const handleResumeRecording = async () => {
    const resumeRecordingStatus = await resumeRecording();
    if (!resumeRecordingStatus) {
      console.error("Failed to resume recording.");
    }
  };

  const stopRecording = async () => {
    sendMessageToExtension({ type: "recordingTime", data: "" });
    if (tutorial) {
      setTutorialHeaderText("Submit recording");
      setTutorialDescription([
        "Your audio is now being processed. Double check all of the information you've provided and make sure everything looks correct before submitting your note.",
        "That's it! You're just seconds away from viewing your first JotPsych note.",
      ]);
    }
    const stopRecordingStatus = await initiateEndRecording();
  };

  const handleSubmitRecording = useCallback(async () => {
    sendMessageToExtension({ type: "recordingTime", data: "" });
    if (elapsedTime < 20) {
      showAlertBanner("Recording must be at least 20 seconds long.", "error");
      return;
    }

    if (!noteInformation.noteTitle) {
      setNoteTitleError(true);
      return;
    }

    setNoteTitleError(false);
    setIsSubmitting(true);
    try {
      const submitRecordingResponse = await sendRecording();
      if (submitRecordingResponse) {
        await handleCheckFirstNote();
        navigate(`/status/${submitRecordingResponse}`);
      } else {
        // showAlertBanner("Failed to submit recording.", "error");
      }
    } catch (error) {
      showAlertBanner("Error occurred while submitting recording.", "error");
    } finally {
      setIsSubmitting(false);
    }
  }, [
    elapsedTime,
    noteInformation.noteTitle,
    sendRecording,
    handleCheckFirstNote,
    navigate,
    showAlertBanner,
  ]);

  const handleDeleteRecording = useCallback(async () => {
    sendMessageToExtension({ type: "recordingTime", data: "" });
    setShowDeleteModal(false);
    const deleteRecordingResponse = await deleteRecordingHelper();
    if (deleteRecordingResponse) {
      showAlertBanner("Recording deleted successfully.", "success");
    } else {
      showAlertBanner("Failed to delete recording.", "error");
    }
  }, [deleteRecordingHelper, showAlertBanner]);

  const handleInitiateReupload = async () => {
    await initiateReupload();
  };

  const RecordingControls = useMemo(() => {
    if (
      recordingStatus.isReuploading ||
      recordingStatus.isStarting ||
      recordingStatus.isStopping
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 100,
          }}
        >
          {spinnerError ? (
            <>
              <Typography color="error">
                An error occurred. Please reset the recorder:
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.removeItem("recordingStatus");
                  window.location.reload();
                }}
              >
                Reset Recorder
              </Button>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              {lowDataMode && (
                <Typography sx={{ mt: 2, color: "lightgrey" }}>
                  Slow network detected, improve your network connection if this
                  takes too long.
                </Typography>
              )}
            </Box>
          )}
        </Box>
      );
    }

    if (recordingStatus.readyToRecord) {
      switch (microphonePermissionGranted) {
        case true:
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {platform === "desktop" && (
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <FormControl fullWidth>
                    <InputLabel id="microphone-select-label">
                      Microphone
                    </InputLabel>
                    <Select
                      labelId="microphone-select-label"
                      value={selectedMicrophone?.deviceId || ""}
                      label="Microphone"
                      onOpen={() => {
                        if (tutorial) {
                          handleInteractAudio();
                        }
                      }}
                      onChange={(event) => {
                        const selectedMic = availableMicrophones?.find(
                          (mic) => mic.deviceId === event.target.value
                        );
                        if (selectedMic) {
                          setSelectedMicrophone(selectedMic);
                        }
                      }}
                    >
                      {availableMicrophones?.map((mic) => (
                        <MenuItem key={mic.deviceId} value={mic.deviceId}>
                          {mic.label || `Microphone ${mic.deviceId}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {browser === "chrome" && (
                    <Tooltip title="Wearing headphones while recording a telehealth visit? Check this box to share audio from the browser tab with your telehealth platform so that we can record it.">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useSharedMedia}
                            onChange={handleCheckboxChange}
                            onMouseEnter={() => {
                              if (tutorial) {
                                handleInteractAudio();
                              }
                            }}
                            color="primary"
                          />
                        }
                        label="Headphones?"
                      />
                    </Tooltip>
                  )}
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={startRecording}
                fullWidth
                onMouseEnter={() => {
                  if (tutorial) {
                    handleInteractAudio();
                  }
                }}
              >
                Start Recording
              </Button>
            </Box>
          );
        case false:
          return (
            <Typography>
              Microphone access is denied. You'll need to enable it to use the
              recorder.
            </Typography>
          );
        case undefined:
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography>
                The recorder needs microphone access to record.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={checkMicrophonePermission}
              >
                Request Microphone Access
              </Button>
            </Box>
          );
      }
    }

    if (recordingStatus.isRecording) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              sx={{
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: "red",
                animation: "pulse 1s infinite",
              }}
            />
            <Typography variant="body2">{timerDisplay}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
            }}
          >
            <Button
              sx={{ width: "180px" }}
              variant="contained"
              color="primary"
              onClick={stopRecording}
            >
              Stop Recording
            </Button>
            <Button
              sx={{ flex: 1 }}
              variant="contained"
              color="inherit"
              onClick={
                recordingStatus.recordingPaused
                  ? handleResumeRecording
                  : pauseRecording
              }
            >
              {recordingStatus.recordingPaused ? "Resume" : "Pause"}
            </Button>
          </Box>
        </Box>
      );
    }

    if (recordingStatus.chunksToBeUploaded) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Timer: {timerDisplay}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleInitiateReupload}
              sx={{ flex: 3 }}
            >
              Retry Upload
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowDeleteModal(true)}
              sx={{ flex: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }

    if (recordingStatus.readyToSubmit) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Timer: {timerDisplay}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitRecording}
              disabled={isSubmitting}
              sx={{ flex: 3 }}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit Recording"
              )}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setShowDeleteModal(true)}
              disabled={isSubmitting}
              sx={{ flex: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }

    return null;
  }, [
    recordingStatus,
    microphonePermissionGranted,
    spinnerError,
    timerDisplay,
    useSharedMedia,
    isSubmitting,
    selectedMicrophone,
    handleSubmitRecording,
    handleDeleteRecording,
  ]);

  return (
    <LayoutWrapper>
      <LayoutWrapper.MainContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 4,
          }}
        >
          <Box
            sx={{
              width: { xs: "95%", md: "80%" },
              maxWidth: 500,
              padding: 2,
            }}
          >
            <Typography variant="h6" textAlign="center" marginBottom={2}>
              Record an Encounter
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="Note Title"
                value={noteInformation.noteTitle || ""}
                onChange={handleNoteTitleChange}
                required
                error={noteTitleError}
                helperText={noteTitleError ? "Please enter a note title" : ""}
              />
              {templatesList && templatesList.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel id="template-label">Template</InputLabel>
                  <Select
                    labelId="template-label"
                    id="template"
                    value={
                      noteInformation.templateId
                        ? noteInformation.templateId
                        : ""
                    }
                    label="Template"
                    onChange={handleTemplateChange}
                    onOpen={() => {
                      if (tutorial) {
                        handleTemplateOpen();
                      }
                    }}
                  >
                    {templatesList.map((template: UserTemplateItem) => (
                      <MenuItem
                        key={template.template_id}
                        value={template.template_id}
                      >
                        {template.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <PatientPicker
                    onOpen={() => {
                      if (tutorial) {
                        handlePatientOpen();
                      }
                    }}
                    onPatientSelect={handlePatientChange}
                    initialPatient={noteInformation.patient}
                  />
                </Box>
                {noteInformation.patient && (
                  <IconButton
                    onClick={() =>
                      navigate(
                        `/patient/${noteInformation.patient?.patient_id}`
                      )
                    }
                    size="small"
                    color="primary"
                    aria-label="view patient details"
                  >
                    <Person />
                  </IconButton>
                )}
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="pronouns-label">Pronouns</InputLabel>
                  <Select
                    labelId="pronouns-label"
                    value={noteInformation.pronouns || ""}
                    label="Pronouns"
                    onChange={handlePronounsChange}
                    onOpen={() => {
                      if (tutorial) {
                        handleInteractAdditionalInfo();
                      }
                    }}
                  >
                    <MenuItem value="he/him">He/Him</MenuItem>
                    <MenuItem value="she/her">She/Her</MenuItem>
                    <MenuItem value="they/them">They/Them</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Age"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  value={noteInformation.age || ""}
                  onChange={handleAgeChange}
                  error={!!ageError}
                  helperText={ageError}
                  onMouseDown={() => {
                    if (tutorial) {
                      handleInteractAdditionalInfo();
                    }
                  }}
                />
              </Box>
              <TextField
                label="Additional Info"
                multiline
                rows={3}
                value={noteInformation.additionalInfo || ""}
                onChange={handleAdditionalInfoChange}
                onMouseDown={() => {
                  if (tutorial) {
                    handleInteractAdditionalInfo();
                  }
                }}
              />

              <Box
                sx={{ minHeight: "100px", height: "10vh", maxHeight: "300px" }}
              >
                <AudioVisualizer />
              </Box>
              {RecordingControls}
            </Box>
            <DeleteModal
              isOpen={showDeleteModal}
              continueText="Delete"
              cancelText="Cancel"
              onCancel={() => {
                setShowDeleteModal(false);
              }}
              onContinue={handleDeleteRecording}
            >
              Are you sure you want to delete this recording? This action is
              permanent and cannot be undone.
            </DeleteModal>
          </Box>

          <VisitSummarySection patient={noteInformation.patient} />

          {userState?.featureFlags?.checklist_feature &&
            currentTranscriptionSessionId &&
            !recordingStatus.readyToRecord && (
              <Box
                sx={{
                  position: "sticky",
                  top: "200px",
                  width: "300px",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Checklist
                  items={{}}
                  transcriptionSessionId={currentTranscriptionSessionId}
                />
              </Box>
            )}
        </Box>
      </LayoutWrapper.MainContent>
      <LayoutWrapper.RightSidebar>
        {tutorial && (
          <DismissableCard
            headerTitle={tutorialHeaderText}
            bodyText={tutorialDescription}
          />
        )}
        <DismissableCard
          headerTitle={"First time here?"}
          bodyText={[
            "No need to install any extra software on your computer or phone. You can record with JotPsych on desktop and mobile by using this recording page.",
            "Want to learn more about recording your first note?",
          ]}
          actionButtonContent={
            <RoundedButton
              variant="contained"
              onClick={() => {
                setTutorial(true);
                setTutorialHeaderText("Let's record our first note!");
                setTutorialDescription([
                  "Great, let's get started. If you aren't comfortable talking to yourself, you may want to grab a partner for a short (3-5 min.) mock session.",
                  "Once you're ready, give your note a title. This can be a name, a topic, or anything you'd like to use to identify this encounter.",
                ]);
              }}
            >
              Start Tour
            </RoundedButton>
          }
          isVisible={!tutorial}
        />
      </LayoutWrapper.RightSidebar>
    </LayoutWrapper>
  );
};

import React, { useState } from "react";
import { Box, Typography, Avatar, Grid, Button } from "@mui/material";
import { Patient } from "../../../types/types";
import SlideSidePane from "../../../Views/Shared/SlideSidePane";
import PatientInformationView from "../PatientInformationView";
import BaseBlock from "./BaseBlock";
import { parseISO } from "date-fns";

interface PatientOverviewBlockProps {
  patient: Patient;
  onPatientUpdate?: (updatedPatient: Patient) => void;
}

const PatientOverviewBlock: React.FC<PatientOverviewBlockProps> = ({
  patient,
  onPatientUpdate,
}) => {
  const [showEditPane, setShowEditPane] = useState(false);

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = parseISO(dateString);
    return date.toLocaleDateString();
  };

  const handlePatientSave = (updatedPatient: Patient) => {
    if (onPatientUpdate) {
      onPatientUpdate(updatedPatient);
    }
  };

  return (
    <>
      <BaseBlock
        title="Patient Overview"
        actions={
          <Button
            variant="outlined"
            size="small"
            onClick={() => setShowEditPane(true)}
          >
            Edit Information
          </Button>
        }
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
          <Avatar
            sx={{
              width: 80,
              height: 80,
              bgcolor: "primary.main",
              fontSize: "2rem",
            }}
          >
            {`${patient.first_name?.[0] || ""}${patient.last_name?.[0] || ""}`}
          </Avatar>

          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              {`${patient.first_name || ""} ${patient.last_name || ""}`}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  Identifier (PRN)
                </Typography>
                <Typography variant="body1">
                  {patient.identifier || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  Date of Birth
                </Typography>
                <Typography variant="body1">
                  {formatDate(patient.dob || "") || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  Gender
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                >
                  {patient.gender || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  Phone Number
                </Typography>
                <Typography variant="body1">
                  {patient.phone || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" color="text.secondary">
                  Email
                </Typography>
                <Typography variant="body1">
                  {patient.email || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BaseBlock>

      <SlideSidePane
        open={showEditPane}
        onClose={() => setShowEditPane(false)}
        title="Edit Patient Information"
      >
        <PatientInformationView
          patientId={patient.patient_id}
          onPatientSave={handlePatientSave}
        />
      </SlideSidePane>
    </>
  );
};

export default PatientOverviewBlock;

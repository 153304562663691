import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { FormDefinition, FormField } from "../../types/types";

interface FormSubmissionViewProps {
  formDefinition: FormDefinition;
  submissionData: Record<string, any>;
}

const FormSubmissionView: React.FC<FormSubmissionViewProps> = ({
  formDefinition,
  submissionData,
}) => {
  const renderFieldValue = (field: FormField, value: any) => {
    switch (field.type) {
      case "checkbox":
        return Array.isArray(value) ? value.join(", ") : value ? "Yes" : "No";
      case "date":
        return new Date(value).toLocaleDateString();
      case "select":
      case "radio":
        // Handle options that might be either strings or FormFieldOption objects
        if (field.options) {
          // @ts-ignore
          const option = field.options.find((opt: any) =>
            typeof opt === "string" ? opt === value : opt.value === value
          );
          return typeof option === "string" ? option : option?.label || value;
        }
        return value;
      default:
        return value || "Not answered";
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 2 }}>
      <Typography variant="h5" gutterBottom>
        {formDefinition.title}
      </Typography>

      {formDefinition.groups.map((group, groupIndex) => (
        <Paper key={groupIndex} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom color="primary">
            {group.title}
          </Typography>

          {group.description && (
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {group.description}
            </Typography>
          )}

          {group.sections.map((section, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                {section.title}
              </Typography>

              {section.fields.map((field) => (
                <Box key={field.id} sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {field.label}
                  </Typography>
                  <Typography variant="body1">
                    {renderFieldValue(field, submissionData[field.id])}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Paper>
      ))}
    </Box>
  );
};

export default FormSubmissionView;

import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  Theme,
  Tooltip,
} from "@mui/material";
import { Patient } from "../../types/types";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";
import { MuiTelInput } from "mui-tel-input";
import { useSnackbar } from "notistack";

interface NewPatientModalProps {
  open: boolean;
  onClose: () => void;
  onSuccessfulSubmission: (patientId: string) => void;
  simplifiedView?: boolean;
  initialName?: string;
}

const NewPatientModal: React.FC<NewPatientModalProps> = ({
  open,
  onClose,
  onSuccessfulSubmission,
  simplifiedView = false,
  initialName,
}) => {
  const { getAccessToken } = useUser();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const getInitialPatientState = useCallback(() => {
    let firstName = "";
    let lastName = "";

    if (initialName) {
      const nameParts = initialName.trim().split(/\s+/);
      firstName =
        (nameParts[0] || "").charAt(0).toUpperCase() +
        (nameParts[0] || "").slice(1);
      lastName =
        nameParts.slice(1).join(" ").charAt(0).toUpperCase() +
          nameParts.slice(1).join(" ").slice(1) || "";
    }

    return {
      first_name: firstName,
      last_name: lastName,
      identifier: "",
      ...(simplifiedView
        ? {}
        : {
            middle_name: "",
            dob: "",
            phone: "",
            email: "",
            gender: "",
            race: "",
            address: "",
          }),
    };
  }, [initialName, simplifiedView]);

  const [patient, setPatient] = useState<
    Omit<Patient, "patient_id" | "updated_at">
  >(getInitialPatientState());

  // Reset form when modal opens or initialName changes
  useEffect(() => {
    setPatient(getInitialPatientState());
  }, [open, initialName, getInitialPatientState]);

  const [errors, setErrors] = useState({
    name: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatient((prev) => ({ ...prev, [name]: value }));
    if (name === "first_name" || name === "last_name") {
      setErrors((prev) => ({ ...prev, name: false }));
    }
  };

  const handlePhoneChange = (newValue: string) => {
    setPatient((prev) => ({ ...prev, phone: newValue }));
  };

  const isNameValid = useCallback(() => {
    return (
      (patient.first_name?.length ?? 0) >= 2 ||
      (patient.last_name?.length ?? 0) >= 2
    );
  }, [patient.first_name, patient.last_name]);

  const handleSubmit = async () => {
    if (!isNameValid()) {
      setErrors((prev) => ({ ...prev, name: true }));
      return;
    }

    setIsSubmitting(true);

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/create",
        accessToken,
        body: { patient_data: patient },
      });

      if (response.ok) {
        const data = await response.value;
        enqueueSnackbar("Patient created successfully", { variant: "success" });
        onSuccessfulSubmission(data.patientId);
        handleClose();
      } else {
        throw new Error(response.error?.message || "Failed to create patient");
      }
    } catch (error) {
      console.error("Error creating patient:", error);
      enqueueSnackbar(
        error instanceof Error
          ? error.message
          : "Failed to create patient. Please try again.",
        {
          variant: "error",
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setPatient({
      first_name: "",
      last_name: "",
      identifier: "",
      ...(simplifiedView
        ? {}
        : {
            middle_name: "",
            dob: "",
            phone: "",
            email: "",
            gender: "",
            race: "",
            address: "",
          }),
    });
    setErrors({ name: false });
    onClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : "80%",
            maxWidth: 600,
            maxHeight: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            <Typography variant="h5" component="div" gutterBottom>
              New Patient
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="First Name"
                name="first_name"
                value={patient.first_name}
                onChange={handleChange}
                error={errors.name && !patient.first_name}
                helperText={
                  errors.name && !patient.first_name
                    ? "First or Last Name is required"
                    : ""
                }
                fullWidth
              />
              <TextField
                label="Last Name"
                name="last_name"
                value={patient.last_name}
                onChange={handleChange}
                error={errors.name && !patient.last_name}
                helperText={
                  errors.name && !patient.last_name
                    ? "First or Last Name is required"
                    : ""
                }
                fullWidth
              />
              <TextField
                label="Identifier"
                name="identifier"
                value={patient.identifier}
                onChange={handleChange}
                fullWidth
              />
              {!simplifiedView && (
                <>
                  <TextField
                    label="Middle Name"
                    name="middle_name"
                    value={patient.middle_name}
                    onChange={handleChange}
                    fullWidth
                  />
                  <TextField
                    label="Date of Birth"
                    name="dob"
                    type="date"
                    value={patient.dob}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                  <MuiTelInput
                    label="Phone"
                    value={patient.phone}
                    onChange={handlePhoneChange}
                    defaultCountry="US"
                    forceCallingCode
                    onlyCountries={["US", "CA", "GB", "AU", "NZ", "ZA", "MX"]}
                    fullWidth
                    sx={{
                      "& input": {
                        height: "1.4375em",
                      },
                    }}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={patient.email}
                    onChange={handleChange}
                    fullWidth
                  />
                  <TextField
                    label="Gender"
                    name="gender"
                    value={patient.gender}
                    onChange={handleChange}
                    fullWidth
                  />
                  <TextField
                    label="Race"
                    name="race"
                    value={patient.race}
                    onChange={handleChange}
                    fullWidth
                  />
                  <TextField
                    label="Address"
                    name="address"
                    value={patient.address}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    fullWidth
                  />
                </>
              )}
            </Box>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "flex-end",
              padding: 2,
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Button onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Tooltip
              title={
                !isNameValid()
                  ? "At least one name field must have at least two characters."
                  : ""
              }
              placement="top"
            >
              <span>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isNameValid()}
                >
                  {isSubmitting ? "Creating..." : "Create Patient"}
                </Button>
              </span>
            </Tooltip>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default NewPatientModal;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import {
  AdminTemplate,
  GamificationType,
  Patient,
  ProviderBillingInfo,
  ReferralInfoType,
  StripeInfoType,
  UserMetricsType,
  UserTemplateItem,
} from "../../types/types";
import APIService from "../../services/APIService";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
// import { clarity } from "../../services/ClarityService";
import { useThemeContext } from "../theme/context";
import { FRONTEND_APP_VERSION } from "../../main";
import { compareVersions } from "../../utils/utils";
import SentryService from "../../services/SentryService";
import { useInterval } from "../../hooks/useInterval";
import { SIDEBAR_DOCKED_KEY } from "../../components/layout/UILayout";
import { saveUserInfoToLocalStorage } from "../../utils/LocalStorageUtils";
import { trackEvent } from "../../utils/analytics_utils";

// Define the new TroubleWordsDict type
export type TroubleWordsDict = {
  correct_spelling: string;
  misspellings: string[];
};

export type UserInfo = {
  name: string;
  email: string;
  institution?: string;
  specialization?: string;
  customerType?: string;
  picture?: string;
  sub?: string;
  roles?: string[];
  featureSet?: string;
};

export type OnboardingSteps = {
  [key: string]: boolean;
};

export type PaymentStatus =
  | "trialing"
  | "active"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "canceled"
  | "unpaid"
  | "paused";

export type UserRole = "admin" | "provider" | "billing_admin";

export interface Specialty {
  specialty_id: string;
  specialty_name: string;
  vocabulary_list: string[];
  templates: { template_id: string; display_name: string }[];
}
export interface NotificationState {
  external_url?: string;
  internal_url?: string;
  type?: string;
  requester_name?: string;
  notification_id?: string;
  title?: string;
  text?: string;
  tooltip?: string;
  createdAt?: number;
  isOpened?: number;
}

export interface UserState {
  user_id?: string;
  featureFlags?: any;
  onboardingSteps?: OnboardingSteps;
  paymentStatus?: PaymentStatus;
  userInfo?: UserInfo;
  group_user_roles?: UserRole[];
  group_ids?: string[];
  gamification?: GamificationType;
  referralInfo: ReferralInfoType;
  userMetrics: UserMetricsType;
  stripeInfo: StripeInfoType;
  lastVersionUsed: { [key: string]: string };
  patientMatchEnrolled?: boolean;
  notifications?: NotificationState[] | null;
  providerBillingInfo?: ProviderBillingInfo;
  firstLoginTime?: number;
  emailVerified?: boolean | null;
  clearinghouseLinked?: boolean | null;
}

export interface Template {
  template_id: string;
  display_name: string;
}

interface UserContextType {
  userState: UserState | null | undefined;
  newFeature: boolean;
  setNewFeature: React.Dispatch<React.SetStateAction<boolean>>;
  setNotifications: (notifications: NotificationState[]) => void;
  awaitingLogin: boolean;
  awaitingSignup: boolean;
  completeSignup: () => void;
  unauthorized: boolean;
  getAccessToken: () => Promise<string | null>;
  updateOnboardingStep: (onboardingStep: string, value: boolean) => void;
  loading: boolean;
  patientsList?: Patient[];
  templatesList?: UserTemplateItem[];
  refreshTemplatesList: () => Promise<void>;
  lockedOut: boolean;
  troubleWords?: TroubleWordsDict[];
  addTroubleWords: (
    newTroubleWords: { correct_spelling: string; misspelling: string }[]
  ) => Promise<boolean>;
  fetchUserInfo: () => Promise<void>;
}

export interface SentryUserContext {
  id: string;
  email: string;
  username: string;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUser(): UserContextType {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userState, setUserState] = useState<UserState | null | undefined>();
  // const [accessToken, setStateAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [unauthorized, setUnauthorizied] = useState(false);
  const [awaitingLogin, setAwaitingLogin] = useState(false);
  const [awaitingSignup, setAwaitingSignup] = useState(false);
  const [newFeature, setNewFeature] = useState(false);
  const [patientsList, setPatientsList] = useState<Patient[]>();
  const [templatesList, setTemplatesList] = useState<UserTemplateItem[]>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [availableSpecialties, setAvailableSpecialties] =
    useState<Specialty[]>();

  const accessToken = useRef<string>();
  // const clarityInitialized = useRef<boolean>(false);

  const [troubleWords, setTroubleWords] = useState<TroubleWordsDict[]>();

  useEffect(() => {
    if (userState && localStorage.getItem("sharedTemplateId")) {
      const sharedTemplateId = localStorage.getItem("sharedTemplateId");
      addSharedTemplateToUser(sharedTemplateId);
    }
  }, [userState]);

  const copyUserTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const copyUserTemplateResponse = await APIService.makeAPIPostRequest({
      requestString: "/template/copyUserTemplate",
      accessToken: accessToken,
      body: {
        template_id: templateId,
      },
    });

    if (copyUserTemplateResponse.ok) {
      refreshTemplatesList();
      localStorage.removeItem("sharedTemplateId");
      localStorage.setItem("sharedTemplateAdded", templateId);
    }
  };

  const copyPublicTemplate = async (templateId: string) => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return;
    }

    const duplicateNewUserTemplateResponse =
      await APIService.makeAPIPostRequest({
        requestString: "/template/copyPublicTemplateToUser",
        accessToken: accessToken,
        body: {
          template_id: templateId,
        },
      });

    if (duplicateNewUserTemplateResponse.ok) {
      refreshTemplatesList();
      localStorage.removeItem("sharedTemplateId");
      localStorage.setItem("sharedTemplateAdded", templateId);
    }
  };

  const addSharedTemplateToUser = async (sharedTemplateId: string | null) => {
    if (sharedTemplateId && sharedTemplateId != "") {
      console.log("adding shared template to my library");
      if (sharedTemplateId.startsWith("u:")) copyUserTemplate(sharedTemplateId);
      else if (sharedTemplateId.startsWith("p:"))
        copyPublicTemplate(sharedTemplateId);
    }
  };

  const getTroubleWords = async () => {
    console.log("getting trouble words: ");
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: "/user/getTroubleWords",
      accessToken: accessToken,
    });
    if (response.ok) {
      const newTroubleWords = response.value.trouble_words;
      setTroubleWords(newTroubleWords);
    }
  };

  const addTroubleWords = async (
    newTroubleWords: { correct_spelling: string; misspelling: string }[]
  ): Promise<boolean> => {
    const requestBody = {
      trouble_words: newTroubleWords,
    };

    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/addTroubleWords",
        accessToken: await getAccessToken(),
        body: requestBody,
      });
      console.log("Response:", response);

      if (response.ok) {
        console.log("Trouble words added successfully");
        return true;
      } else {
        console.error("Failed to add trouble words");
        return false;
      }
    } catch (error) {
      console.error("Error details:", error);
      return false;
    }
  };

  const {
    user: auth0User,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
  } = useAuth0();
  const { setBrand } = useThemeContext();

  const setAccessToken = (newAccessToken: string) => {
    localStorage.setItem("accessToken", newAccessToken);
    accessToken.current = newAccessToken;
  };

  const tokenExpired = (token: string): boolean => {
    try {
      // Decode the token (JWT) to get the JSON object it contains
      const payload = JSON.parse(atob(token.split(".")[1]));
      // The `exp` claim in a JWT is the expiration time of the token, in seconds since the epoch
      const expirationTimeInSeconds = payload.exp;
      // Get the current time in seconds since the epoch
      const nowInSeconds = Date.now() / 1000;
      // Compare the current time with the expiration time
      return nowInSeconds >= expirationTimeInSeconds;
    } catch {
      console.log("malformed token");
      return true;
    }
  };

  const checkAndRefreshToken = async (): Promise<string | null> => {
    try {
      let token = accessToken.current;

      // If there's no token or it's expired, refresh it
      if (!token || tokenExpired(token)) {
        const response = await getAccessTokenSilently({
          detailedResponse: true,
        });
        token = response.access_token;
        if (response) setAccessToken(response.access_token);
      }

      return token;
    } catch (error) {
      if (error instanceof Error) {
        // Handle specific error for invalid refresh token
        if (error.message?.includes("Unknown or invalid refresh token")) {
          console.error(
            "Invalid refresh token - user needs to re-authenticate"
          );
          setAwaitingLogin(true);
          return null;
        }

        // Handle other errors except missing refresh token
        if (!error.message?.startsWith("Missing Refresh Token")) {
          console.error("Error refreshing token:", error);
        }
      }
      setAwaitingLogin(true);
      return null;
    }
  };

  const getAccessToken = async (): Promise<string> => {
    // Logic to get and refresh the access token
    const accessToken = await checkAndRefreshToken();

    if (accessToken) {
      return accessToken;
    } else {
      return "";
    }
  };

  const initAuth = async () => {
    setLoading(true);

    await checkAndRefreshToken();

    if (accessToken.current) {
      setAwaitingLogin(false);
      setIsAuthenticated(true);
    } else {
      // No token in storage, set awaitingLogin to prompt for login
      setAwaitingLogin(true);
      setUserState(null);
    }

    setLoading(false);
  };

  const checkForNewNotifications = async () => {
    if (!accessToken.current) return;

    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: "/user/checkNewNotifications",
        accessToken: await getAccessToken(),
      });

      if (response.ok && response.value.user_notifications) {
        setUserState((prevState) => {
          if (!prevState) {
            return prevState;
          }

          // Only update if notifications have changed
          if (
            JSON.stringify(prevState.notifications) !==
            JSON.stringify(response.value.user_notifications)
          ) {
            return {
              ...prevState,
              notifications: response.value.user_notifications,
            };
          }

          return prevState;
        });
      }
    } catch (error) {
      console.error("Error checking for new notifications:", error);
    }
  };

  // Use the custom hook to call checkForNewNotifications every 10 minutes
  useInterval(checkForNewNotifications, 10 * 60 * 1000);

  const setNotifications = (notifications: NotificationState[]) => {
    setUserState((prevState) => {
      if (!prevState) {
        return prevState;
      }
      return {
        ...prevState,
        notifications: notifications,
      };
    });
  };

  const fetchTemplates = async () => {
    if (!accessToken) {
      return;
    }

    const templatesResponse = await APIService.makeAPIGetRequest({
      requestString: "/user/getTemplates",
      accessToken: await getAccessToken(),
    });
    if (templatesResponse.ok) {
      setTemplatesList(templatesResponse.value.templates);
    }
  };

  const fetchUserInfo = async () => {
    if (!accessToken) {
      return;
    }

    try {
      const userInfoResponse = await APIService.makeAPIGetRequest({
        requestString: "/user/getUserInfo",
        accessToken: await getAccessToken(),
      });

      if (userInfoResponse.ok) {
        const win: Window = window;

        const data = userInfoResponse.value;
        let userInfo = data.user_info;
        userInfo = {
          ...userInfo,
          featureFlags: data.feature_flags,
          payment_status: data.payment_status,
          email_verified: data.email_verified,
        };

        saveUserInfoToLocalStorage(userInfo, auth0User);

        const isOriginInList = (urls: string, origin: string): boolean => {
          return urls
            .split(",")
            .map((url) => url.trim())
            .includes(origin);
        };

        // In your context code:
        if (userInfoResponse.value.feature_flags?.next) {
          if (
            !isOriginInList(import.meta.env.VITE_NEXT_URLS, win.location.origin)
          ) {
            console.log("switching user to next");
            win.location =
              import.meta.env.VITE_NEXT_URLS.split(",")[0].trim() +
              win.location.pathname;
          }
        } else {
          if (
            !isOriginInList(import.meta.env.VITE_MAIN_URLS, win.location.origin)
          ) {
            console.log("switching user to main");
            win.location =
              import.meta.env.VITE_MAIN_URLS.split(",")[0].trim() +
              win.location.pathname;
          }
        }

        // switch brand based on brand
        if (userInfoResponse.value.brand) {
          if (
            ["jotpsych", "jotpal", "smartscribe"].includes(
              userInfoResponse.value.brand
            )
          ) {
            // Save to localStorage and update brand if the brand is valid
            localStorage.setItem(
              "preferred_brand",
              userInfoResponse.value.brand
            );
            setBrand(userInfoResponse.value.brand);
          }
        }

        if (userInfoResponse.value.user_id) {
          trackEvent({
            event: "userID",
            user_id: userInfoResponse.value.user_id,
          });
        }

        if (userInfoResponse.value.user_info) {
          if (
            !userInfoResponse.value.last_version_used?.frontend ||
            compareVersions(
              userInfoResponse.value.last_version_used?.frontend,
              FRONTEND_APP_VERSION
            ) < 0
          ) {
            setNewFeature(true);

            // Reset sidebar docked state
            localStorage.setItem(SIDEBAR_DOCKED_KEY, "false");

            APIService.makeAPIPostRequest({
              requestString: "/user/updateLastVersionUsed",
              accessToken: await getAccessToken(),
              body: { platform: "frontend", version: FRONTEND_APP_VERSION },
            });
          }
        }

        const newUserState = {
          user_id: userInfoResponse.value.user_id,
          userInfo: userInfoResponse.value.user_info,
          featureFlags: userInfoResponse.value.feature_flags,
          paymentStatus: userInfoResponse.value.payment_status,
          onboardingSteps: userInfoResponse.value.onboarding_steps,
          group_user_roles: userInfoResponse.value.group_user_roles,
          group_ids: userInfoResponse.value.group_ids,
          gamification: userInfoResponse.value.gamification,
          referralInfo: userInfoResponse.value.referral_info,
          emailVerified: userInfoResponse.value.email_verified,
          userMetrics: userInfoResponse.value.user_metrics,
          stripeInfo: userInfoResponse.value.stripe_info,
          brand: userInfoResponse.value.brand,
          lastVersionUsed: userInfoResponse.value.last_version_used,
          patientMatchEnrolled: userInfoResponse.value.patient_match_enrolled,
          notifications: userInfoResponse.value.user_notifications,
          providerBillingInfo: userInfoResponse.value.provider_billing_info,
          firstLoginTime: userInfoResponse.value.first_login_time,
          clearinghouseLinked: userInfoResponse.value.clearinghouse_linked,
        };

        setUserState(newUserState);

        if (!newUserState?.userInfo?.name) {
          setAwaitingSignup(true);
          await fetchAvailableSpecialties();
        }
      } else {
        throw new Error("Can't get UserInfo.");
      }
    } catch (e: unknown) {
      console.error(`fetchUserInfo Error: ${e}`);
      throw e;
    }
  };

  const fetchAvailableSpecialties = async () => {
    if (!accessToken) {
      return;
    }

    const availableSpecialtiesResponse = await APIService.makeAPIGetRequest({
      requestString: "/specialties/getAvailableSpecialties",
      accessToken: await getAccessToken(),
    });

    if (availableSpecialtiesResponse.ok) {
      setAvailableSpecialties(availableSpecialtiesResponse.value.specialties);
    }
  };

  const updateOnboardingStep = async (
    onboardingStep: string,
    value: boolean
  ) => {
    if (!accessToken) {
      return;
    }

    const onboardingUpdateResponse = await APIService.makeAPIPostRequest({
      requestString: "/onboarding/updateOnboardingSteps",
      accessToken: await getAccessToken(),
      body: {
        [onboardingStep]: value,
      },
    });
    if (onboardingUpdateResponse.ok && userState) {
      setUserState({
        ...userState,
        onboardingSteps: {
          ...userState.onboardingSteps,
          [onboardingStep]: value,
        },
      });
    }
  };

  const updateNewLogin = async () => {
    if (!accessToken) return;

    const loginResponse = await APIService.makeAPIPostRequest({
      requestString: "/user/userLogin",
      accessToken: await getAccessToken(),
      body: {
        device_info: { platform: "chrome_extension" },
      },
    });
  };

  // const getNewAccessToken = async () => {
  //   try {
  //     console.log("Getting new access token")
  //     const newToken = await getAccessTokenSilently();
  //     setAccessToken(newToken);
  //     setAwaitingLogin(false);
  //     setIsAuthenticated(true);
  //     // Save the new token for future sessions
  //     localStorage.setItem("accessToken", newToken);
  //   } catch (e) {
  //     console.log("Couldn't get access token, showing login screen");
  //     setAwaitingLogin(true);
  //   }
  // };

  const refreshTemplatesList = async () => {
    await fetchTemplates();
  };

  const handleLogUserContext = async () => {
    const claims = await getIdTokenClaims();

    if (claims) {
      const userContext: SentryUserContext = {
        id: claims.sub as string,
        email: claims.email as string,
        username: claims.name as string,
      };
      SentryService.setUserContext(userContext);
    }
  };

  const fetchData = async () => {
    try {
      if (isAuthenticated) {
        handleLogUserContext();
        await fetchUserInfo(); // Wait for fetchUserInfo to finish before continuing

        // Now that fetchUserInfo has finished, we can fetch templates and patients
        // Since the order of these two does not matter, we can start both of them at the same time
        const fetchTemplatesPromise = fetchTemplates();
        const fetchTroubleWordsPromise = getTroubleWords();

        // Now we wait for both of the promises to resolve
        await Promise.all([fetchTemplatesPromise, fetchTroubleWordsPromise]);
      }
    } catch (e: unknown) {
      console.error(`fetchData Error: ${e}`);
      setUnauthorizied(true);
    }
  };

  const completeSignup = () => {
    updateOnboardingStep("account_creation_onboarding_completed", true);
    setAwaitingSignup(false);
  };

  // get user information on Authentication
  useEffect(() => {
    fetchData();
  }, [isAuthenticated]);

  useEffect(() => {
    initAuth();

    // if (!clarityInitialized.current && !clarity.hasStarted()) {
    //   clarity.init("jdkbo469we");
    //   clarity.consent();
    //   clarityInitialized.current = true;
    //   console.log("Initializing Clarity...");
    // }
  }, []);

  // useEffect(() => {
  //   if (userState) {
  //     if (clarity.hasStarted() && userState.user_id) {
  //       clarity.identify(userState.user_id, {});
  //     }
  //   }
  // }, [userState]);

  // Effect for Clarity initialization
  useEffect(() => {}, []);

  const lockedOut =
    userState?.paymentStatus === "paused" ||
    userState?.paymentStatus === "past_due" ||
    userState?.paymentStatus === "canceled" ||
    userState?.paymentStatus === "unpaid";

  const value = {
    userState,
    awaitingSignup,
    completeSignup,
    awaitingLogin,
    newFeature,
    setNewFeature,
    setNotifications,
    unauthorized,
    getAccessToken,
    updateOnboardingStep,
    loading,
    patientsList,
    templatesList,
    refreshTemplatesList,
    lockedOut,
    troubleWords,
    addTroubleWords,
    fetchUserInfo,
    // getTroubleWords
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  CircularProgress,
  useTheme,
  Alert,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import {
  Paid,
  InfoOutlined,
  Edit as EditIcon,
  Check as CheckIcon,
  MoneyOutlined,
  ThumbUp,
  ThumbDown,
  Close,
  Add,
  NoteAdd,
  Info,
  Help,
} from "@mui/icons-material";
import { ContentCard } from "../../Views/Shared/ContentCard";
import { ConditionalTooltip } from "../ConditionalTooltip";
import { RoundedButton } from "../../styles/CustomButtons";
import { GreenBubble, YellowBubble, RedBubble } from "../Icons/ColoredBubbles";
import {
  BillingCodeExtraData,
  NoteExtraType,
  Patient,
} from "../../types/types";
import { UserState, useUser } from "../../context/user/context";
import { styled } from "@mui/material/styles";
import { Tooltip as MuiTooltip } from "@mui/material";
import { trackEvent } from "../../utils/analytics_utils";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import BillingCodeSection from "./BillingCodeSection";
import { AnimatedCheckmark } from "../SVGIcon";
import APIService from "../../services/APIService";
import { Paper } from "@mui/material";

interface BillingSidebarDataCollectionProps {
  noteId: string;
  billingSubmitted?: boolean;
  status: "processing" | "completed" | "errored";
  initialPredictedCodes?: {
    billing_data_id: string;
    predicted_billing_data: {
      predicted_codes: {
        cpt_code: string;
        description?: string;
        charge: string;
        diagnostic_codes: {
          code: string;
          description?: string;
        }[];
      }[];
      metadata?: {
        confidence_score: number;
        warnings?: string[];
      };
    };
  };
}

interface BillingDataCollectionInfo {
  data_collection_submissions: number;
}

interface DiagnosticCode {
  code: string;
  description?: string;
}

interface CPTCodeEntry {
  cpt_code: string;
  description?: string;
  charge: string;
  diagnostic_codes: DiagnosticCode[];
}

const BillingSidebarDataCollection: React.FC<
  BillingSidebarDataCollectionProps
> = ({ noteId, billingSubmitted = false, status, initialPredictedCodes }) => {
  const { getAccessToken, userState } = useUser();
  const hasTrackedRef = useRef(false);
  const [cptEntries, setCptEntries] = useState<CPTCodeEntry[]>(
    initialPredictedCodes?.predicted_billing_data?.predicted_codes?.map(
      (code) => ({
        cpt_code: code.cpt_code,
        description: code.description,
        charge: code.charge,
        diagnostic_codes: code.diagnostic_codes,
      })
    ) || [
      {
        cpt_code: "",
        description: "",
        charge: "",
        diagnostic_codes: [{ code: "", description: "" }],
      },
    ]
  );
  const [isSubmitted, setIsSubmitted] = useState(billingSubmitted);
  const [progress, setProgress] = useState(0);
  const [submissionCount, setSubmissionCount] = useState<number>(0);
  const [showNote, setShowNote] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [warnings, setWarnings] = useState<string[]>(
    initialPredictedCodes?.predicted_billing_data?.metadata?.warnings || []
  );
  const [feedbackComment, setFeedbackComment] = useState("");
  const [showFeedbackComment, setShowFeedbackComment] = useState(false);
  const [rating, setRating] = useState<boolean | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasSeenTooltip = localStorage.getItem(
        "billing-collection-tooltip-seen"
      );
      if (!hasSeenTooltip) {
        setShowTooltip(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      try {
        const response = await APIService.makeAPIGetRequest({
          requestString: "/user/getBillingDataCollectionInfo",
          accessToken: await getAccessToken(),
        });

        if (response.ok) {
          const submissions = response.value?.data_collection_submissions || 0;
          setSubmissionCount(submissions);
          setProgress(Math.min((submissions / 10) * 100, 100));
        }
      } catch (error) {
        console.error("Error fetching billing info:", error);
      }
    };

    fetchBillingInfo();
  }, [getAccessToken]);

  useEffect(() => {
    if (billingSubmitted) {
      setIsSubmitted(true);
      setSubmissionCount((prev) => Math.max(prev + 1, 1));
      setProgress((prev) =>
        Math.min((Math.max(submissionCount + 1, 1) / 10) * 100, 100)
      );
    }
  }, [billingSubmitted]);

  useEffect(() => {
    if (initialPredictedCodes?.predicted_billing_data?.predicted_codes) {
      setCptEntries(
        initialPredictedCodes.predicted_billing_data.predicted_codes.map(
          (code) => ({
            cpt_code: code.cpt_code,
            description: code.description,
            charge: code.charge,
            diagnostic_codes: code.diagnostic_codes,
          })
        )
      );

      if (initialPredictedCodes.predicted_billing_data.metadata?.warnings) {
        setWarnings(
          initialPredictedCodes.predicted_billing_data.metadata.warnings
        );
      }
    }
  }, [initialPredictedCodes]);

  useEffect(() => {
    if (hasTrackedRef.current && initialPredictedCodes?.billing_data_id) {
      handleSubmission({ viewed: true });
    }
  }, [hasTrackedRef.current]);

  const handleTooltipClick = () => {
    setIsModalOpen(true);
    setShowTooltip(false);
    localStorage.setItem("billing-collection-tooltip-seen", "true");
  };

  const handleMouseEnter = () => {
    if (!hasTrackedRef.current) {
      trackEvent({
        event: "billing_sidebar_data_collection_mouse_enter",
      });
      hasTrackedRef.current = true;
    }
  };

  const handleCPTCodeChange = (index: number, value: string) => {
    const newEntries = [...cptEntries];
    newEntries[index].cpt_code = value;
    setCptEntries(newEntries);
  };

  const handleChargeChange = (index: number, value: string) => {
    const newEntries = [...cptEntries];
    newEntries[index].charge = value;
    setCptEntries(newEntries);
  };

  const handleDiagCodeChange = (
    cptIndex: number,
    diagIndex: number,
    value: string
  ) => {
    const newEntries = [...cptEntries];
    newEntries[cptIndex].diagnostic_codes[diagIndex] = {
      code: value,
      description:
        newEntries[cptIndex].diagnostic_codes[diagIndex]?.description,
    };
    setCptEntries(newEntries);
  };

  const addCPTCode = () => {
    if (cptEntries.length < 8) {
      setCptEntries([
        ...cptEntries,
        {
          cpt_code: "",
          description: "",
          charge: "",
          diagnostic_codes: [{ code: "", description: "" }],
        },
      ]);
    }
  };

  const removeCPTCode = (index: number) => {
    const newEntries = cptEntries.filter((_, i) => i !== index);
    setCptEntries(newEntries);
  };

  const addDiagCode = (cptIndex: number) => {
    if (cptEntries[cptIndex].diagnostic_codes.length < 8) {
      const newEntries = [...cptEntries];
      newEntries[cptIndex].diagnostic_codes.push({ code: "", description: "" });
      setCptEntries(newEntries);
    }
  };

  const removeDiagCode = (cptIndex: number, diagIndex: number) => {
    const newEntries = [...cptEntries];
    newEntries[cptIndex].diagnostic_codes = newEntries[
      cptIndex
    ].diagnostic_codes.filter((_, i) => i !== diagIndex);
    setCptEntries(newEntries);
  };

  const handleSubmission = async (payload: any) => {
    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/notes/submitBillingPredictionDataCollection",
        accessToken: await getAccessToken(),
        body: {
          billing_data_id: initialPredictedCodes?.billing_data_id,
          note_id: noteId,
          ...payload,
        },
      });

      if (!response.ok) {
        throw new Error(response.error?.message || "Submission failed");
      }
    } catch (error) {
      console.error("Submission error:", error);
      setError(
        error instanceof Error ? error.message : "An unexpected error occurred"
      );
    }
  };

  const handleRating = async (isPositive: boolean) => {
    setRating(isPositive);
    if (!isPositive) {
      setShowFeedbackComment(true);
    }
    await handleSubmission({ rating: isPositive });
  };

  const handleFeedbackComment = async () => {
    if (feedbackComment.trim()) {
      await handleSubmission({ comment: feedbackComment });
      setShowFeedbackComment(false);
      setFeedbackComment("");
    }
  };

  const handleSubmit = async () => {
    setError(null);
    setIsSubmitting(true);

    // Validate required fields
    const hasInvalidEntries = cptEntries.some(
      (entry) =>
        !entry.cpt_code || !entry.charge || isNaN(parseFloat(entry.charge))
    );

    if (hasInvalidEntries) {
      setError("Each CPT entry must have a valid code and numeric charge");
      setIsSubmitting(false);
      return;
    }

    const modifiedBillingData = {
      cpt_codes: cptEntries.map((entry) => ({
        cpt_code: entry.cpt_code,
        charge: parseFloat(entry.charge),
        diagnostic_codes: entry.diagnostic_codes
          .filter((code) => code.code.trim() !== "")
          .map((code) => ({ diagnostic_code: code.code })),
      })),
    };

    try {
      // Create submission payload
      const submissionPayload: any = {
        modified_billing_data: modifiedBillingData,
      };

      // Add comment if noteText is present
      if (noteText.trim()) {
        submissionPayload.comment = noteText.trim();
      }

      await handleSubmission(submissionPayload);
      setIsSubmitted(true);
      const newCount = submissionCount + 1;
      setSubmissionCount(newCount);
      setProgress(Math.min((newCount / 10) * 100, 100));

      // Reset form
      setCptEntries([
        {
          cpt_code: "",
          charge: "",
          diagnostic_codes: [{ code: "", description: "" }],
        },
      ]);
      setNoteText("");
      setShowNote(false);
    } catch (error) {
      console.error("Submission error:", error);
      setError(
        error instanceof Error ? error.message : "An unexpected error occurred"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => setIsModalOpen(false);

  const extensionContent = (
    <Box sx={{ width: "100%", p: 2, borderBottom: 1, borderColor: "divider" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
            {isSubmitted
              ? "Thanks! We're learning from your feedback."
              : rating === false
              ? "Please make corrections below to help us learn"
              : "How does this look?"}
          </Typography>
          {!isSubmitted && (
            <>
              <IconButton
                size="small"
                sx={{ mr: 1 }}
                onClick={() => handleRating(true)}
                color={rating === true ? "primary" : "default"}
              >
                <ThumbUp fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleRating(false)}
                color={rating === false ? "primary" : "default"}
              >
                <ThumbDown fontSize="small" />
              </IconButton>
            </>
          )}
        </Box>
      </Box>

      {showFeedbackComment && (
        <Box sx={{ mt: 1 }}>
          <TextField
            size="small"
            fullWidth
            multiline
            rows={2}
            value={feedbackComment}
            onChange={(e) => setFeedbackComment(e.target.value)}
            placeholder="What could be improved?"
            sx={{ mb: 1 }}
          />
          <Button
            size="small"
            variant="contained"
            onClick={handleFeedbackComment}
            disabled={!feedbackComment.trim()}
          >
            Submit Feedback
          </Button>
        </Box>
      )}

      {submissionCount < 10 && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            "& .MuiLinearProgress-bar": {
              transition: "transform 1s ease-in-out",
            },
          }}
        />
      )}
      {isSubmitted && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
            animation: "fadeIn 0.5s ease-in-out",
          }}
        >
          <AnimatedCheckmark />
        </Box>
      )}
    </Box>
  );

  return (
    <Box>
      <Box onMouseEnter={handleMouseEnter}>
        <ContentCard
          sx={{ minHeight: "auto", width: "100%" }}
          icon={<Paid fontSize="medium" sx={{ mr: 2 }} />}
          title="Billing Information"
          hideContent={isSubmitted}
          extension={extensionContent}
          content={
            status === "processing" ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {cptEntries.map((entry, cptIndex) => (
                  <Box
                    key={cptIndex}
                    sx={{
                      mb: 1,
                      p: 0.5,
                      border: 1,
                      borderColor: "divider",
                      borderRadius: 1,
                      "&:hover": {
                        bgcolor: "action.hover",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 0.5, mb: 0.5 }}>
                      {cptIndex > 0 && (
                        <Box
                          sx={{
                            width: 20,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            "&:hover .number": {
                              opacity: 0,
                            },
                            "&:hover .delete-icon": {
                              opacity: 1,
                            },
                          }}
                        >
                          <Typography
                            variant="caption"
                            className="number"
                            sx={{
                              color: "text.secondary",
                              position: "absolute",
                              opacity: 1,
                              transition: "opacity 0.2s",
                            }}
                          >
                            {cptIndex + 1}.
                          </Typography>
                          <Typography
                            component="span"
                            onClick={() => removeCPTCode(cptIndex)}
                            className="delete-icon"
                            sx={{
                              color: "text.secondary",
                              cursor: "pointer",
                              position: "absolute",
                              opacity: 0,
                              transition: "opacity 0.2s",
                              "&:hover": {
                                color: "error.main",
                              },
                            }}
                          >
                            <Close sx={{ fontSize: 14 }} />
                          </Typography>
                        </Box>
                      )}
                      <Tooltip
                        title={entry.description || ""}
                        arrow
                        disableHoverListener={!entry.description}
                        placement="left"
                      >
                        <TextField
                          size="small"
                          placeholder="CPT Code"
                          value={entry.cpt_code}
                          onChange={(e) =>
                            handleCPTCodeChange(cptIndex, e.target.value)
                          }
                          sx={{
                            width: "60%",
                          }}
                        />
                      </Tooltip>
                      <TextField
                        size="small"
                        placeholder="$"
                        value={entry.charge}
                        onChange={(e) =>
                          handleChargeChange(cptIndex, e.target.value)
                        }
                        sx={{
                          width: "40%",
                        }}
                      />
                    </Box>

                    <Box sx={{ pl: 1 }}>
                      {entry.diagnostic_codes.map((diagCode, diagIndex) => (
                        <Box
                          key={diagIndex}
                          sx={{ display: "flex", gap: 0.5, mb: 0.5 }}
                        >
                          <Box
                            sx={{
                              width: 20,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                              ...(diagIndex > 0 && {
                                "&:hover .number": {
                                  opacity: 0,
                                },
                                "&:hover .delete-icon": {
                                  opacity: 1,
                                },
                              }),
                            }}
                          >
                            <Typography
                              variant="caption"
                              className="number"
                              sx={{
                                color: "text.secondary",
                                position: "absolute",
                                opacity: 1,
                                transition: "opacity 0.2s",
                              }}
                            >
                              {diagIndex + 1}.
                            </Typography>
                            {diagIndex > 0 && (
                              <Typography
                                component="span"
                                onClick={() =>
                                  removeDiagCode(cptIndex, diagIndex)
                                }
                                className="delete-icon"
                                sx={{
                                  color: "text.secondary",
                                  cursor: "pointer",
                                  position: "absolute",
                                  opacity: 0,
                                  transition: "opacity 0.2s",
                                  "&:hover": {
                                    color: "error.main",
                                  },
                                }}
                              >
                                <Close sx={{ fontSize: 14 }} />
                              </Typography>
                            )}
                          </Box>
                          <Tooltip
                            title={diagCode.description || ""}
                            arrow
                            disableHoverListener={!diagCode.description}
                            placement="left"
                          >
                            <TextField
                              size="small"
                              placeholder="Dx Code"
                              value={diagCode.code}
                              onChange={(e) =>
                                handleDiagCodeChange(
                                  cptIndex,
                                  diagIndex,
                                  e.target.value
                                )
                              }
                              sx={{
                                flexGrow: 1,
                                "& .MuiInputBase-root": {
                                  height: 32,
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>
                      ))}
                      {entry.diagnostic_codes.length < 8 && (
                        <Button
                          size="small"
                          onClick={() => addDiagCode(cptIndex)}
                          startIcon={<Add sx={{ fontSize: 16 }} />}
                          sx={{
                            minWidth: 0,
                            py: 0,
                            px: 1,
                            fontSize: "0.75rem",
                          }}
                        >
                          Dx
                        </Button>
                      )}
                    </Box>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  {cptEntries.length < 8 && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={addCPTCode}
                      startIcon={<Add sx={{ fontSize: 16 }} />}
                      sx={{
                        py: 0.5,
                        fontSize: "0.75rem",
                      }}
                    >
                      CPT Code
                    </Button>
                  )}

                  {!showNote && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setShowNote(!showNote)}
                      startIcon={<NoteAdd sx={{ fontSize: 16 }} />}
                      sx={{
                        py: 0.5,
                        fontSize: "0.75rem",
                      }}
                      color={showNote ? "primary" : "inherit"}
                    >
                      Comment
                    </Button>
                  )}
                </Box>

                {showNote && (
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    placeholder="Anything else we should know?"
                    sx={{ mb: 2 }}
                  />
                )}

                {error && (
                  <Typography
                    color="error"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontSize: "0.875rem",
                    }}
                  >
                    {error}
                  </Typography>
                )}

                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  sx={{ py: 1 }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>

                {isSubmitted && (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    Billing data submitted successfully!
                  </Alert>
                )}
              </Box>
            )
          }
        />
      </Box>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="billing-info-modal"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            position: "relative",
            maxWidth: 400,
            p: 4,
            outline: "none",
            m: 2,
          }}
        >
          <IconButton
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close sx={{ fontSize: 14 }} />
          </IconButton>

          <Typography variant="h6" component="h2" gutterBottom>
            Help Us Improve & Save $25
          </Typography>

          <Typography sx={{ mb: 2 }}>
            We're collecting information to learn about how you bill and how we
            can make that easier for you in the future.
          </Typography>

          <Typography sx={{ mb: 2 }}>
            To help us, please include the CPT and Diagnostic codes that you
            would use to bill this encounter, as well as the amount you would
            charge for each.
          </Typography>

          <Typography sx={{ mb: 2 }}>
            As a thank you, after you submit 10 entires, you'll receive:
          </Typography>

          <Typography sx={{ mb: 2 }} color="primary.main" fontWeight="bold">
            $25 off your next month's subscription
          </Typography>

          <Typography color="text.secondary" sx={{ mb: 2 }}>
            Your contributions help us build better features tailored to your
            needs. Thank you for participating!
          </Typography>

          <Typography color="text.secondary">
            Note: Information you submit is for informational purposes only and
            will not be shared with any insurance payer or other third party.
          </Typography>
        </Paper>
      </Modal>
    </Box>
  );
};

export default BillingSidebarDataCollection;

import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Modal,
  Card,
  CardContent,
  TextField,
  Alert,
  Snackbar,
  InputAdornment,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import {
  Patient,
  NoteDataType,
  ProviderBillingInfo,
  BillingCodeExtraData,
} from "../types/types";
import APIService from "../services/APIService";
import { useUser } from "../context/user";
import { formatDate, formatDuration } from "../utils/utils";
import { RoundedButton } from "../styles/CustomButtons";
import ReactConfetti from "react-confetti";
import { CustomTransitionModal } from "../components/Modals/CustomModal";
import { ConditionalTooltip } from "../components/ConditionalTooltip";
import { GreenBubble, YellowBubble } from "../components/Icons/ColoredBubbles";
import { ClearinghouseLinked } from "./Shared/ClaimMDHelperFunctionalities";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import BillingCodeSection from "../components/Billing/BillingCodeSection";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { trackEvent } from "../utils/analytics_utils";

interface BillingSubmissionViewProps {
  patient?: Patient | null;
  providerBillingInfo?: ProviderBillingInfo;
  note?: NoteDataType;
  patientId?: string;
  providerId?: string;
  noteId?: string;
  billingCodeExtra: BillingCodeExtraData;
  missingPatientBillingFields: string[];
  missingProviderBillingFields: string[];
  onBillingSubmitted: () => void;
  onBillingCodeUpdate: (newBillingCode: string) => Promise<void>;
}

interface ChargeField {
  diagnosisCode: string;
  chargeAmount: string;
  units: string;
  billingCode: string;
  modifier: string;
}

const BillingSubmissionView: React.FC<BillingSubmissionViewProps> = ({
  patient: initialPatient,
  note: initialNote,
  providerBillingInfo,
  patientId,
  noteId,
  billingCodeExtra,
  missingPatientBillingFields,
  missingProviderBillingFields,
  onBillingSubmitted,
  onBillingCodeUpdate,
}) => {
  const [patient, setPatient] = useState<Patient | null | undefined>(
    initialPatient || null
  );
  const [isClearinghouseLinked, setIsClearinghouseLinked] =
    useState<boolean>(false);
  const [note, setNote] = useState<NoteDataType | null>(initialNote || null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [eSignatureChecked, setESignatureChecked] = useState<boolean>(false);
  const [isConfettiActive, setIsConfettiActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showClearinghouseModal, setShowClearinghouseModal] =
    useState<boolean>(false);
  const [billingFields, setBillingFields] = useState(() => {
    const savedFields = localStorage.getItem(`billingFields-${noteId}`);
    return savedFields
      ? JSON.parse(savedFields)
      : billingCodeExtra.billing_codes.billing_codes.map((billing) => ({
          code: billing.code,
          chargeAmount: "",
          units: "1",
          modifier: "",
          diagnosisCodes:
            billingCodeExtra.diagnosis_codes.billing_code_mapping
              .find((mapping) => mapping.cpt_code === billing.code)
              ?.supported_diagnosis_codes.map((diagnosis) => diagnosis.code) ||
            [],
        }));
  });
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);

  const { getAccessToken, userState } = useUser();
  const win: Window = window;

  const calculateTotalServiceCharge = () => {
    return billingFields
      .reduce((total: number, field: any) => {
        const charge = parseFloat(field.chargeAmount) || 0;
        const units = parseInt(field.units, 10) || 0;
        return total + charge;
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const accessToken = await getAccessToken();

      try {
        if (patientId && !patient) {
          console.log("Fetching patient data");
          const patientResponse = await APIService.makeAPIGetRequest({
            requestString: `/patient/get?patient_id=${patientId}`,
            accessToken,
          });
          if (patientResponse.ok) {
            setPatient(patientResponse.value.patient);
          }
        }

        if (noteId && !note) {
          console.log("Fetching note data");
          const noteResponse = await APIService.makeAPIGetRequest({
            requestString: `/notes/${noteId}`,
            accessToken,
          });
          if (noteResponse.ok) {
            setNote(noteResponse.value);
          }
        }
      } catch (err) {
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [patientId, noteId, patient, note, getAccessToken]);

  useEffect(() => {
    if (userState?.clearinghouseLinked) {
      setIsClearinghouseLinked(true);
    }
  }, [userState?.clearinghouseLinked]);

  useEffect(() => {
    localStorage.setItem(
      `billingFields-${noteId}`,
      JSON.stringify(billingFields)
    );
  }, [billingFields]);

  const mapPayerRelationship = (relationship: string | undefined): string => {
    switch (relationship?.toLowerCase()) {
      case "spouse":
        return "01";
      case "self":
        return "18";
      case "child":
        return "19";
      case "employee":
        return "20";
      case "unknown":
        return "21";
      case "life partner":
        return "53";
      case "dependent":
        return "G8";
      default:
        return "18"; // Default to 'Unknown' if the relationship is not recognized
    }
  };

  const mapPayerGender = (gender: string | undefined): string => {
    switch (gender?.toLowerCase()) {
      case "male":
        return "M";
      case "female":
        return "F";
      default:
        return "U"; // Default to 'Unknown' if the gender is not recognized
    }
  };

  const mapBillingTaxIdType = (taxIdType: string | undefined): string => {
    switch (taxIdType?.toLowerCase()) {
      case "ssn":
        return "S";
      case "ein":
        return "E";
      default:
        throw new Error("Invalid tax ID type"); // Throw an error for invalid input
    }
  };

  const mapAcceptAssign = (acceptAssign: string | undefined): string => {
    switch (acceptAssign?.toLowerCase()) {
      case "yes":
        return "Y";
      case "no":
        return "N";
      default:
        return "Y"; // Default to 'Y' if the input is not recognized
    }
  };

  const handleBillingFieldChange = (
    index: number,
    field: keyof (typeof billingFields)[0],
    value: string
  ) => {
    const newBillingFields = [...billingFields];
    //@ts-ignore
    newBillingFields[index][field] = value;
    setBillingFields(newBillingFields);
  };

  const handleAddDiagnosisCode = (index: number, code: string) => {
    const newBillingFields = [...billingFields];
    if (code && !newBillingFields[index].diagnosisCodes.includes(code)) {
      newBillingFields[index].diagnosisCodes.push(code);
      setBillingFields(newBillingFields);
    }
  };

  const handleRemoveDiagnosisCode = (index: number, code: string) => {
    const newBillingFields = [...billingFields];
    newBillingFields[index].diagnosisCodes = newBillingFields[
      index
    ].diagnosisCodes.filter((c: string) => c !== code);
    setBillingFields(newBillingFields);
  };

  const handleAddBillingCodeSection = () => {
    setBillingFields([
      ...billingFields,
      {
        code: "",
        chargeAmount: "",
        units: "1",
        modifier: "",
        diagnosisCodes: [],
      },
    ]);
  };

  const handleRemoveBillingCodeSection = (index: number) => {
    console.debug("Removing billing code section at index:", index);
    const newBillingFields = billingFields.filter(
      (_: any, i: number) => i !== index
    );
    console.debug("New billing fields after removal:", newBillingFields);
    setBillingFields(newBillingFields);
  };

  const convertToYYYYMMDD = (dateString: string) => {
    const months: { [key: string]: string } = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const [month, day, year] = dateString.split(" ");
    const formattedDay = day.replace(",", "");

    return `${year}-${months[month]}-${formattedDay.padStart(2, "0")}`;
  };

  const timeUploaded = note?.time_uploaded
    ? convertToYYYYMMDD(formatDate(note.time_uploaded, "datelong"))
    : "";

  const handleSubmitBilling = useCallback(async () => {
    if (!isClearinghouseLinked) {
      setShowClearinghouseModal(true);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessToken();

      const requestBody = {
        note_id: noteId,
        patient_info: {
          first_name: patient?.first_name,
          last_name: patient?.last_name,
          dob: patient?.dob,
          address: patient?.address,
          gender: mapPayerGender(patient?.gender),
          payer_id: patient?.payer_id,
          payer_name: patient?.payer_name,
          payer_relationship: mapPayerRelationship(patient?.payer_relationship),
          insurance_policy_number: patient?.insurance_policy_number,
          city: patient?.city,
          state: patient?.state,
          zip: patient?.zip,
        },
        provider_info: {
          name: `${providerBillingInfo?.first_name} ${providerBillingInfo?.last_name}`,
          npi_number: providerBillingInfo?.npi_number,
          billing_npi_number: providerBillingInfo?.billing_npi_number,
          facility_address: providerBillingInfo?.facility_address,
          facility_address2: providerBillingInfo?.facility_address2,
          city: providerBillingInfo?.city,
          state: providerBillingInfo?.state,
          tax_id: providerBillingInfo?.tax_id,
          tax_id_type: mapBillingTaxIdType(providerBillingInfo?.tax_id_type),
          accept_insurance_payment_as_full: mapAcceptAssign(
            providerBillingInfo?.accept_insurance_payment_as_full
          ),
          phone: providerBillingInfo?.phone,
          zip: providerBillingInfo?.zip,
          place_of_service: providerBillingInfo?.place_of_service,
        },
        cpt_code: billingCodeExtra.billing_codes.billing_codes[0].code,
        session_date: timeUploaded,
        charge_fields: billingFields.map((field: any) => ({
          diagnosis_codes: field.diagnosisCodes,
          charge_amount: field.chargeAmount,
          units: field.units,
          billing_code: field.code,
          modifier: field.modifier,
        })),
        total_service_charge: calculateTotalServiceCharge(),
      };

      const response = await APIService.makeAPIPostRequest({
        requestString: "/billing/billing/submit",
        accessToken,
        body: requestBody,
      });

      if (response.ok) {
        console.log("Billing submitted successfully");
        setIsSubmissionSuccessful(true); // Set submission success
        setIsConfettiActive(true);
        setTimeout(() => {
          setIsConfettiActive(false);
          onBillingSubmitted();
        }, 2000); //2 seconds
      } else {
        console.log(response.error);
        setError(response.error.message || "Failed to submit billing");
      }
    } catch (err) {
      console.error("Error submitting billing:", err);
      setError("Failed to submit billing. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [
    isClearinghouseLinked,
    patient,
    providerBillingInfo,
    billingCodeExtra,
    note,
    billingFields,
    getAccessToken,
    patient?.payer_relationship,
    timeUploaded,
    onBillingSubmitted,
  ]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const InfoBox: React.FC<{ title: string; data: Record<string, any> }> = ({
    title,
    data,
  }) => (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(data).map(([key, value]) => (
          <Grid item xs={6} key={key}>
            <Typography variant="subtitle2">
              {key.replace(/_/g, " ").toUpperCase()}
            </Typography>
            <Typography>{value || "N/A"}</Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );

  // Move the getWindowDimensions function outside of the component
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  // Render logic
  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (isSubmissionSuccessful) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Claim Submitted Successfully!
        </Typography>
        <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Your billing claim has been submitted.
        </Typography>
      </Box>
    );
  }

  const isPayerIdMissing = !patient?.payer_id;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {isConfettiActive && (
        <>
          <ReactConfetti
            width={getWindowDimensions().width}
            height={getWindowDimensions().height}
            recycle={false}
            numberOfPieces={300}
            colors={["#85bb65", "#4CAF50", "#2E7D32", "#1B5E20"]}
            drawShape={(ctx) => {
              ctx.fillStyle = ctx.strokeStyle;
              ctx.beginPath();
              ctx.rect(-15, -7, 30, 14);
              ctx.fill();
              ctx.stroke();
              ctx.closePath();
            }}
            gravity={0.08}
            wind={0.01}
          />
          <ReactConfetti
            width={getWindowDimensions().width}
            height={getWindowDimensions().height}
            recycle={false}
            numberOfPieces={200}
            colors={["#FFD700", "#FFA500", "#C0C0C0", "#CD7F32"]}
            gravity={0.2}
            drawShape={(ctx) => {
              ctx.fillStyle = ctx.strokeStyle;
              ctx.beginPath();
              ctx.arc(0, 0, 8, 0, Math.PI * 2);
              ctx.fill();
              ctx.stroke();
              ctx.closePath();
            }}
          />
        </>
      )}
      <Box sx={{ flexGrow: 1, overflowY: "auto", pb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Billing Submission
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <ClearinghouseLinked
              isClearinghouseLinked={isClearinghouseLinked}
              setIsClearinghouseLinked={setIsClearinghouseLinked}
              showModal={showClearinghouseModal}
              setShowModal={setShowClearinghouseModal}
              onLinkClick={() => {
                trackEvent({
                  event: "clearinghouse_linked_click_billing",
                  event_category: "Clearinghouse",
                  event_label:
                    "Clearinghouse Linked on Billing Submission Page",
                });
              }}
            />
          </Box>
        </Box>

        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Charge Fields
          </Typography>
          {billingFields.map((billingField: any, index: number) => (
            <BillingCodeSection
              key={index}
              index={index}
              billingField={billingField}
              onFieldChange={handleBillingFieldChange}
              onAddDiagnosisCode={handleAddDiagnosisCode}
              onRemoveDiagnosisCode={handleRemoveDiagnosisCode}
              onDeleteSection={handleRemoveBillingCodeSection}
            />
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddBillingCodeSection}
            variant="outlined"
            sx={{ mt: 2 }}
          >
            Add Billing Code Section
          </Button>

          <Divider sx={{ my: 2 }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="h6">Total Service Charge:</Typography>
            <Typography variant="h6" fontWeight="bold">
              ${calculateTotalServiceCharge()}
            </Typography>
          </Box>
        </Paper>

        {note && (
          <InfoBox
            title="Session Information"
            data={{
              "Note Title": note.note_title,
              "Session Date": formatDate(note.time_uploaded, "datelong"),
              "Session Time": note.transcription_start_timestamp
                ? `${formatDate(
                    note.transcription_start_timestamp,
                    "time"
                  )} – ${formatDate(note.transcription_end_timestamp, "time")}`
                : formatDate(note.time_uploaded, "time"),
              "Session Duration": note.transcription_length_seconds
                ? formatDuration(note.transcription_length_seconds)
                : "N/A",
            }}
          />
        )}

        {patient && (
          <InfoBox
            title="Patient Information"
            data={{
              "First Name": patient.first_name,
              "Last Name": patient.last_name,
              //Identifier: patient.identifier,
              "Date of Birth": patient.dob,
              Address: patient.address,
              Gender: patient.gender,
              "Payer ID": patient.payer_id,
              "Payer Name": patient.payer_name,
              "Payer Relationship": `${
                patient.payer_relationship
              } (${mapPayerRelationship(patient.payer_relationship)})`,
              "Insurance Policy Number": patient.insurance_policy_number,
              City: patient.city,
              State: patient.state,
              Zip: patient.zip,
            }}
          />
        )}

        {providerBillingInfo && (
          <InfoBox
            title="Provider Information"
            data={{
              Name:
                providerBillingInfo.first_name +
                " " +
                providerBillingInfo.last_name,
              "Rendering NPI Number": providerBillingInfo.npi_number,
              "Billing NPI Number": providerBillingInfo.billing_npi_number,
              "Place of Service": providerBillingInfo.place_of_service,
              "Facility Address": providerBillingInfo.facility_address,
              "Facility Address 2": providerBillingInfo.facility_address2,
              City: providerBillingInfo.city,
              State: providerBillingInfo.state,
              "Tax ID": providerBillingInfo.tax_id,
              "Tax ID Type": providerBillingInfo.tax_id_type,
              "Accepts Insurance Payment as Full":
                providerBillingInfo.accept_insurance_payment_as_full,
              Phone: providerBillingInfo.phone,
              Zip: providerBillingInfo.zip,
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          pt: 2,
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={eSignatureChecked}
              onChange={(e) => setESignatureChecked(e.target.checked)}
            />
          }
          label={
            <Typography variant="body2">
              I hereby certify that the information provided is true and
              accurate to the best of my knowledge.
            </Typography>
          }
        />
        <ConditionalTooltip
          title="You need to complete all patient and provider information to submit billing"
          condition={
            isPayerIdMissing ||
            missingPatientBillingFields.length > 0 ||
            missingProviderBillingFields.length > 0
          }
        >
          <span>
            <RoundedButton
              variant="contained"
              sx={{
                fontSize: "0.85rem",
                fontWeight: "700",
                whiteSpace: "nowrap",
                px: 2,
              }}
              color="primary"
              onClick={() => {
                trackEvent({
                  event: "submit_billing_final",
                  event_category: "Billing",
                  event_label: "Submit on Billing Submission Page",
                });
                handleSubmitBilling();
              }}
              disabled={
                !eSignatureChecked ||
                isPayerIdMissing ||
                missingPatientBillingFields.length > 0 ||
                missingProviderBillingFields.length > 0 ||
                billingFields.length === 0 ||
                billingFields[0].diagnosisCodes.length === 0 ||
                billingFields[0].chargeAmount.length === 0 ||
                billingFields[0].units.length === 0
              }
            >
              Submit
            </RoundedButton>
          </span>
        </ConditionalTooltip>
      </Box>

      <CustomTransitionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="billing-submission-modal"
        aria-describedby="billing-submission-result"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "95%", sm: "80%" },
            maxWidth: 600,
            maxHeight: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            {error ? (
              <>
                <Typography
                  id="billing-submission-modal"
                  variant="h5"
                  component="div"
                  gutterBottom
                  color="error"
                >
                  Error Submitting Billing
                </Typography>
                <Typography
                  id="billing-submission-result"
                  variant="body1"
                  sx={{ mt: 2 }}
                >
                  {error}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  id="billing-submission-modal"
                  variant="h5"
                  component="div"
                  gutterBottom
                >
                  Thanks for testing!
                </Typography>
                <Typography
                  id="billing-submission-result"
                  variant="body1"
                  sx={{ mt: 2 }}
                >
                  We're still testing this feature, and your bill wasn't
                  actually submitted. 😢
                </Typography>
                <Typography
                  id="billing-submission-result"
                  variant="body1"
                  sx={{ mt: 2 }}
                >
                  But we would love your feedback on what you'd like to see in
                  this feature. Please fill out this short survey.
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <RoundedButton
                    variant="contained"
                    onClick={() => {
                      window.open(
                        "https://docs.google.com/forms/d/1RzZun-PoatIeU3B_fXI0MCTFMB8_nwA0jfBN65Z8Bj4/edit",
                        "_blank"
                      );
                    }}
                  >
                    Provide Feedback
                  </RoundedButton>
                </Box>
              </>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button variant="contained" onClick={handleCloseModal}>
                Close
              </Button>
            </Box>
          </CardContent>
        </Card>
      </CustomTransitionModal>
    </Box>
  );
};

export default BillingSubmissionView;
